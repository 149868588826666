import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import AuthLayout from '~/pages/_layouts/auth';
import DashboardLayout from '~/pages/_layouts/dashboard';

import { store } from '~/store';

export default function RouteWrapper({ component: Component, isPrivate, isAdmin, ...rest }) {
  const { signed, user } = store.getState().auth;
  const userIsAdmin = user?.is_admin || false;

  if (!signed && isPrivate) return <Redirect to="/" />;
  if (signed && !isPrivate) return <Redirect to="/overview" />;
  if (isAdmin && !userIsAdmin) return <Redirect to="/overview" />;

  const Layout = signed ? DashboardLayout : AuthLayout;

  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};
