import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  background: '#FFF';
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;

  form {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    button {
      height: 46px;
      text-transform: none;
      background: #009b9f;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 40px;
      font-size: 18px;
      transition: background 0.2s, width 0.5s;

      &:hover {
        background: #008185;
      }
    }

    a {
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }
  }
`;
