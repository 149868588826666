import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { listStatementsRequest, setSelectedStatement } from '~/store/modules/statement/actions';

import { formatCurrency, getPrettyStatementStatus, stringToLongPrettyDate } from '~/services/utils';
import StatementManagementDialog from './Dialog';

const useStyles = makeStyles(() => ({
  root: {
    padding: 12,
  },

  addButton: {
    fontSize: 13,
    width: 150,
  },

  basic: {
    fontSize: 12,
    padding: 5,
    height: 35,
    borderRadius: 10,
    border: '1px solid #DDD',
    textAlign: 'center',
  },
}));

export default function StatementManagement() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { statements, newStatement } = useSelector(state => state.statement);

  const handleAdd = () => {
    dispatch(setSelectedStatement(newStatement));
  };

  const handleEdit = statement => {
    dispatch(setSelectedStatement(statement));
  };

  useEffect(() => {
    dispatch(listStatementsRequest());
  }, [dispatch]);

  return (
    <>
      <StatementManagementDialog />

      <Box align="center" className={classes.root}>
        <Box display="flex" justifyContent="space-between" width="100%" mb="12px">
          <Typography variant="h5" style={{ fontWeight: 'bold', lineHeight: '36px' }} color="secondary">
            Faturas
          </Typography>

          <Button onClick={() => handleAdd()} variant="contained" color="primary" className={classes.addButton} startIcon={<AddIcon />}>
            Fatura
          </Button>
        </Box>

        <Table size="small" style={{ maxHeight: 'calc(100vh - 108px)' }}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.basic} style={{ width: '20%', fontWeight: 'bold' }}>
                Usuário
              </TableCell>
              <TableCell className={classes.basic} style={{ width: '20%', fontWeight: 'bold' }} align="center">
                Descrição
              </TableCell>
              <TableCell className={classes.basic} style={{ width: '20%', fontWeight: 'bold' }}>
                Valor Total
              </TableCell>
              <TableCell className={classes.basic} style={{ width: '20%', fontWeight: 'bold' }}>
                Data de Vencimento
              </TableCell>
              <TableCell className={classes.basic} style={{ width: '20%', fontWeight: 'bold' }}>
                Status
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {statements.map(statement => (
              <TableRow key={statement.id} hover onClick={() => handleEdit(statement)} style={{ cursor: 'pointer' }}>
                <TableCell className={classes.basic} style={{ width: '20%' }}>
                  {statement.user.username}
                </TableCell>
                <TableCell className={classes.basic} style={{ width: '20%' }}>
                  {statement.description}
                </TableCell>
                <TableCell className={classes.basic} style={{ width: '20%' }}>
                  {formatCurrency(statement.total_price)}
                </TableCell>
                <TableCell className={classes.basic} style={{ width: '20%' }}>
                  {stringToLongPrettyDate(statement.due_date)}
                </TableCell>
                <TableCell className={classes.basic} style={{ width: '20%' }}>
                  {getPrettyStatementStatus(statement.status)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
}
