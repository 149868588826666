export function listStatementReservationsRequest(userID, startDate, endDate) {
  return {
    type: '@statement/LIST_STATEMENT_RESERVATIONS_REQUEST',
    payload: {
      userID,
      startDate,
      endDate,
    },
  };
}

export function listStatementReservationsSuccess(reservations) {
  return {
    type: '@statement/LIST_STATEMENT_RESERVATIONS_SUCCESS',
    payload: {
      reservations,
    },
  };
}

export function listStatementReservationsFailure() {
  return {
    type: '@statement/LIST_STATEMENT_RESERVATIONS_FAILURE',
  };
}

export function listUserStatementsRequest() {
  return {
    type: '@statement/LIST_USER_STATEMENTS_REQUEST',
  };
}

export function listUserStatementsSuccess(statements) {
  return {
    type: '@statement/LIST_USER_STATEMENTS_SUCCESS',
    payload: {
      statements,
    },
  };
}

export function listUserStatementsFailure() {
  return {
    type: '@statement/LIST_USER_STATEMENTS_FAILURE',
  };
}

export function listStatementsRequest() {
  return {
    type: '@statement/LIST_STATEMENTS_REQUEST',
  };
}

export function listStatementsSuccess(statements) {
  return {
    type: '@statement/LIST_STATEMENTS_SUCCESS',
    payload: {
      statements,
    },
  };
}

export function listStatementsFailure() {
  return {
    type: '@statement/LIST_STATEMENTS_FAILURE',
  };
}

export function clearSelectedStatement() {
  return {
    type: '@statement/CLEAR_SELECTED_STATEMENT',
  };
}

export function setSelectedStatement(selectedStatement) {
  return {
    type: '@statement/SET_SELECTED_STATEMENT',
    payload: {
      selectedStatement,
    },
  };
}

export function updateSelectedStatementInfo(field, value) {
  return {
    type: '@statement/UPDATE_SELECTED_STATEMENT_INFO',
    payload: {
      field,
      value,
    },
  };
}

export function createStatementRequest(statement) {
  return {
    type: '@statement/CREATE_STATEMENT_REQUEST',
    payload: {
      user_id: statement.user_id,
      description: statement.description,
      due_date: statement.due_date,
      total_price: statement.total_price,
      reservations: statement.reservations,
    },
  };
}

export function createStatementSuccess(statement) {
  return {
    type: '@statement/CREATE_STATEMENT_SUCCESS',
    payload: {
      statement,
    },
  };
}

export function createStatementFailure() {
  return {
    type: '@statement/CREATE_STATEMENT_FAILURE',
  };
}

export function updateStatementRequest(statementID) {
  return {
    type: '@statement/UPDATE_STATEMENT_REQUEST',
    payload: {
      statementID,
    },
  };
}

export function updateStatementSuccess(statement) {
  return {
    type: '@statement/UPDATE_STATEMENT_SUCCESS',
    payload: {
      statement,
    },
  };
}

export function updateStatementFailure() {
  return {
    type: '@statement/UPDATE_STATEMENT_FAILURE',
  };
}

export function deleteStatementRequest(statementID) {
  return {
    type: '@statement/DELETE_STATEMENT_REQUEST',
    payload: {
      statementID,
    },
  };
}

export function deleteStatementSuccess(deletedStatement) {
  return {
    type: '@statement/DELETE_STATEMENT_SUCCESS',
    payload: {
      deletedStatement,
    },
  };
}

export function deleteStatementFailure() {
  return {
    type: '@statement/DELETE_STATEMENT_FAILURE',
  };
}
