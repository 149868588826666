import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import user from './user/sagas';
import env from './env/sagas';
import reservation from './reservation/sagas';
import shift_reservation from './shift_reservation/sagas';
import contract from './contract/sagas';
import statement from './statement/sagas';
import room from './room/sagas';
import admin from './admin/sagas';

export default function* rootSaga() {
  return yield all([auth, user, env, reservation, shift_reservation, contract, statement, room, admin]);
}
