import React, { forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { Box, Drawer, List, ListItem, Button, colors, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import OverviewIcon from '@material-ui/icons/LibraryBooks';
import CalendarIcon from '@material-ui/icons/Today';
import Fade from '@material-ui/core/Fade';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import SettingsIcon from '@material-ui/icons/Settings';
import AdminIcon from '@material-ui/icons/SupervisorAccount';
import ContractIcon from '@material-ui/icons/Loop';
import RoomIcon from '@material-ui/icons/Weekend';
import StatementIcon from '@material-ui/icons/AttachMoney';

import { toggleMenu } from '~/store/modules/env/actions';
import menu_logo from '~/assets/menu_logo.svg';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'hidden',
    backgroundColor: theme.palette.white,
    flexDirection: 'column',
    height: '100%',
    display: 'flex',
  },

  drawer: {
    width: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      marginTop: 48,
      height: 'calc(100% - 48px)',
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  list: {
    padding: 0,
  },
  listClose: {
    padding: 0,
    paddingTop: 48,
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    height: 60,
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  active: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.secondary.main,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 25,
    height: 48,
    minHeight: 48,
  },
  hide: {
    display: 'none',
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const Sidebar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const menuOpen = useSelector(state => state.env.menuOpen);
  const { has_contract: hasContract, is_admin: isAdmin } = useSelector(state => state.auth.user);

  const [bottomPages, setBottomPages] = useState([]);
  const [upperPages, setUpperPages] = useState([]);

  const handleDrawer = () => {
    dispatch(toggleMenu());
  };

  useEffect(() => {
    const list = [
      {
        title: 'Visualização Geral',
        href: '/overview',
        icon: <OverviewIcon />,
      },
      {
        title: 'Hora Avulsa',
        href: '/hour_calendar',
        icon: <CalendarIcon />,
      },
      {
        title: 'Turno Avulso',
        href: '/shift_calendar',
        icon: <CalendarIcon />,
      },
      {
        title: 'Faturas',
        href: '/statement',
        icon: <StatementIcon />,
      },
    ];

    setUpperPages(list);
  }, [hasContract]);

  useEffect(() => {
    const list = [];

    if (isAdmin) {
      list.push({
        title: 'Gerência de Faturas',
        href: '/management/statement',
        icon: <StatementIcon />,
      });
      list.push({
        title: 'Gerência de Contratos',
        href: '/management/contract',
        icon: <ContractIcon />,
      });
      list.push({
        title: 'Gerência de Reservas',
        href: '/management/reservation',
        icon: <CalendarIcon />,
      });
      list.push({
        title: 'Gerência de Usuários',
        href: '/management/user',
        icon: <AdminIcon />,
      });
      list.push({
        title: 'Gerência de Salas',
        href: '/management/room',
        icon: <RoomIcon />,
      });
    }
    list.push({
      title: 'Configurações',
      href: '/profile',
      icon: <SettingsIcon />,
    });

    setBottomPages(list);
  }, [isAdmin]);

  return (
    <Drawer
      classes={{
        paper: clsx({
          [classes.drawerOpen]: menuOpen,
          [classes.drawerClose]: !menuOpen,
        }),
      }}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: menuOpen,
        [classes.drawerClose]: !menuOpen,
      })}
      open={menuOpen}
      variant="permanent"
    >
      <div className={classes.root}>
        <div
          className={clsx(classes.toolbar, {
            [classes.hide]: !menuOpen,
          })}
        >
          <img alt="Logo" src={menu_logo} width="75%" />

          <IconButton onClick={handleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
          <List
            className={clsx({
              [classes.list]: menuOpen,
              [classes.listClose]: !menuOpen,
            })}
          >
            {upperPages.map(page => (
              <ListItem className={classes.item} disableGutters key={page.title}>
                <Tooltip placement="right" title={menuOpen ? '' : page.title} TransitionComponent={Fade}>
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={page.href}>
                    <div className={classes.icon}>{page.icon}</div>
                    {menuOpen ? page.title : null}
                  </Button>
                </Tooltip>
              </ListItem>
            ))}
          </List>
          <List
            className={clsx({
              [classes.list]: menuOpen,
              [classes.listClose]: !menuOpen,
            })}
            style={{ marginBottom: 24 }}
          >
            {bottomPages.map(page => (
              <ListItem className={classes.item} disableGutters key={page.title}>
                <Tooltip placement="right" title={menuOpen ? '' : page.title} TransitionComponent={Fade}>
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={page.href}>
                    <div className={classes.icon}>{page.icon}</div>
                    {menuOpen ? page.title : null}
                  </Button>
                </Tooltip>
              </ListItem>
            ))}
          </List>
        </Box>
      </div>
    </Drawer>
  );
};

export default Sidebar;
