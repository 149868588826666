import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import clsx from 'clsx';

import _ from 'lodash';
import { stringToPrettyDate } from '~/services/utils';

const useStyles = makeStyles(theme => ({
  basic: {
    fontSize: 12,
    padding: 5,
    height: 28,
    border: '1px solid #DDD',
    textAlign: 'center',
  },
  container: {
    height: 'calc(100vh - 150px)',
  },
  tableRow: {
    background: '#FFF',
  },
  sameDateNext: {
    borderBottom: '1px solid #FFF',
  },
  sameDatePrev: {
    borderTop: '1px solid #FFF',
  },
}));

export default function Overview() {
  const classes = useStyles();

  const { userUpcomingReservations } = useSelector(state => state.reservation);
  const [reservations, setReservations] = useState([]);

  useEffect(() => {
    setReservations(_.sortBy(userUpcomingReservations, ['date', 'time']));
  }, [userUpcomingReservations]);

  return (
    <TableContainer className={classes.container}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.basic} style={{ textAlign: 'left', fontWeight: 'bold' }}>
              Data
            </TableCell>
            <TableCell className={classes.basic} style={{ textAlign: 'left', fontWeight: 'bold' }}>
              Hora
            </TableCell>
            <TableCell className={classes.basic} style={{ textAlign: 'left', fontWeight: 'bold' }}>
              Sala
            </TableCell>
            <TableCell className={classes.basic} style={{ textAlign: 'left', fontWeight: 'bold' }}>
              Anotação
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reservations.map(({ date, time, room, note }, index) => {
            let sameDatePrev = false;
            let sameDateNext = false;
            if (index > 0) {
              const prevDate = reservations[index - 1].date;
              if (date === prevDate) sameDatePrev = true;
            }
            if (index < reservations.length - 1) {
              const nextDate = reservations[index + 1].date;
              if (date === nextDate) sameDateNext = true;
            }

            return (
              <TableRow key={index} hover className={classes.tableRow}>
                <TableCell
                  className={clsx(classes.basic, { [classes.sameDateNext]: sameDateNext, [classes.sameDatePrev]: sameDatePrev })}
                  style={{ textAlign: 'left', fontWeight: 'bold', background: '#FFF' }}
                >
                  {!sameDatePrev && stringToPrettyDate(date)}
                </TableCell>
                <TableCell className={classes.basic} style={{ textAlign: 'left' }}>
                  {time}
                </TableCell>
                <TableCell className={classes.basic} style={{ textAlign: 'left' }}>
                  {room.name}
                </TableCell>
                <TableCell className={classes.basic} style={{ textAlign: 'left' }}>
                  {note || '-'}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
