import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography, Grid, Button, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

import { clearSelectedRoom, deleteRoomRequest, mergeRoomRequest, updateSelectedRoomInfo } from '~/store/modules/room/actions';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    padding: '24px 24px 16px 24px',
  },

  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 24px 24px 24px',
  },

  activeButton: {
    width: 120,
    background: '#FFF',
    color: theme.palette.primary.main,
    boxShadow: 'none',
    '&:hover': {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main,
    },
  },
}));

export default function RoomManagementDialog() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { selectedRoom, mergeAction } = useSelector(state => state.room);

  const handleClose = () => {
    dispatch(clearSelectedRoom());
  };

  const handleChange = e => {
    dispatch(updateSelectedRoomInfo(e.target.name, e.target.value));
  };

  const handleMerge = () => {
    dispatch(mergeRoomRequest(selectedRoom, mergeAction));
  };

  const handleDelete = roomId => {
    dispatch(deleteRoomRequest(roomId));
  };

  return (
    <Dialog open={!!selectedRoom} onClose={handleClose} maxWidth="sm">
      <Box className={classes.dialogTitle} display="flex" justifyContent="space-between" width="100%">
        <Typography variant="h4">
          <b>Cadastro de Sala</b>
        </Typography>
        <IconButton size="small" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        {selectedRoom && (
          <Grid container spacing={2}>
            <Grid item container xs={12} alignItems="center">
              <TextField
                autoComplete="off"
                name="name"
                value={selectedRoom.name}
                onChange={e => handleChange(e)}
                variant="outlined"
                placeholder="Nome da Sala"
                label="Nome da Sala"
                fullWidth
              />
            </Grid>
            <Grid item container xs={12} alignItems="center">
              <TextField
                autoComplete="off"
                name="hour_price"
                value={selectedRoom.hour_price}
                onChange={e => handleChange(e)}
                variant="outlined"
                placeholder="35.00"
                label="Preço hora"
                fullWidth
              />
            </Grid>
            <Grid item container xs={12} alignItems="center">
              <TextField
                autoComplete="off"
                name="shift_hourly_price"
                value={selectedRoom.shift_hourly_price}
                onChange={e => handleChange(e)}
                variant="outlined"
                placeholder="25.00"
                label="Preço por hora em turno"
                fullWidth
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {mergeAction === 'update' ? (
          <Button onClick={() => handleDelete(selectedRoom.id)} variant="contained" className={classes.activeButton}>
            {selectedRoom.is_active ? 'Desativar' : 'Ativar'}
          </Button>
        ) : (
          <div />
        )}

        <Button onClick={handleMerge} variant="contained" color="primary" style={{ width: 120 }} startIcon={<CheckIcon />}>
          {mergeAction === 'create' ? 'Criar' : 'Atualizar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
