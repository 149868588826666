import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPasswordRequest, clearError } from '~/store/modules/user/actions';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import logo from '~/assets/logo.svg';
import { Link } from 'react-router-dom';
import { Box, Card, TextField, Typography, Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  card: {
    padding: 32,
    borderRadius: 12,
    '& .MuiTextField-root': {
      marginBottom: 16,
    },
    width: '50%',
    maxWidth: 500,
    minWidth: 315,
  },
  textBox: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  linkText: {
    marginLeft: 4,
    fontWeight: 'bold',
  },
  button: {
    width: '100%',
  },
  buttonLoading: {
    width: '30%',
    minWidth: 60,
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, forgotPasswordError } = useSelector(state => state.user);

  useEffect(() => {
    dispatch(clearError());
    return () => {
      dispatch(clearError());
    };
  }, [dispatch]);

  const [email, setEmail] = useState('');

  const handleSignIn = e => {
    e.preventDefault();

    dispatch(forgotPasswordRequest(email));
  };

  return (
    <Card className={classes.card} elevation={5}>
      <img src={logo} alt="Viver Integral Coworking" />
      <form onSubmit={handleSignIn} noValidate>
        <TextField
          name="email"
          autoComplete="off"
          value={email}
          onChange={e => setEmail(e.target.value)}
          variant="outlined"
          placeholder="exemplo@gmail.com"
          label="Email"
          type="email"
        />

        <Box height={16} mb={2}>
          {forgotPasswordError && (
            <Typography variant="subtitle2" color="error">
              {forgotPasswordError}
            </Typography>
          )}
        </Box>

        <Box display="flex" justifyContent="center" width="100%">
          <Button
            type="submit"
            className={clsx(classes.button, {
              [classes.buttonLoading]: loading,
            })}
          >
            {loading ? <CircularProgress color="inherit" size={30} /> : 'Solicitar troca de senha'}
          </Button>
        </Box>

        <Box display="flex" justifyContent="center" mt={2} className={classes.textBox}>
          <Typography variant="h5">Lembrou sua senha?</Typography>

          <Link to="/">
            <Typography variant="h5" color="secondary" className={classes.linkText}>
              Acesse aqui!
            </Typography>
          </Link>
        </Box>
      </form>
    </Card>
  );
}
