import React from 'react';
import { useDispatch } from 'react-redux';

import { Box, Typography, Grid, Button, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';

import { isMobile } from 'react-device-detect';

import {
  createHourReservationRequest,
  updateHourReservationRequest,
  deleteHourReservationRequest,
} from '~/store/modules/reservation/actions';

import { stringToPrettyDate, isCancelable, getReservationTimeLabel } from '~/services/utils';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    padding: '24px 24px 16px 24px',
  },

  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 24px 24px 24px',
  },

  deleteButton: {
    background: '#FFF',
    color: theme.palette.error.main,
    boxShadow: 'none',
    '&:hover': {
      color: theme.palette.error.contrastText,
      background: theme.palette.error.dark,
    },
  },
}));

export default function HourlyDialog({ selectedReservation, setSelectedReservation, selectedAction, setSelectedAction }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClose = () => {
    setSelectedReservation(null);
    setSelectedAction(null);
  };

  const handleReserve = () => {
    const reservation = { ...selectedReservation, room_id: selectedReservation.room.id };
    delete reservation.room;

    if (selectedAction === 'create') {
      dispatch(createHourReservationRequest(reservation));
    } else {
      dispatch(updateHourReservationRequest(reservation));
    }

    handleClose();
  };

  const handleDelete = () => {
    dispatch(deleteHourReservationRequest(selectedReservation.id));

    handleClose();
  };

  const handleChangeNote = note => {
    setSelectedReservation({ ...selectedReservation, note });
  };

  return (
    <Dialog open={!!selectedReservation && !!selectedAction} onClose={handleClose} maxWidth="md">
      <Box className={classes.dialogTitle} display="flex" justifyContent="space-between" width="100%">
        <Typography variant="h4">
          <b>Confirme {isMobile ? '' : 'os dados de'} sua reserva</b>
        </Typography>
        <IconButton size="small" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        {selectedReservation && (
          <Grid container spacing={1}>
            <Grid item container xs={12} alignItems="center">
              <Typography variant="h5" gutterBottom color="secondary">
                <b>{stringToPrettyDate(selectedReservation.date)}</b> - <b>{getReservationTimeLabel(selectedReservation)}</b>
              </Typography>
            </Grid>
            <Grid item container xs={12} alignItems="center">
              <Typography variant="h5" gutterBottom color="secondary" style={{ marginRight: 4 }}>
                <b>Sala:</b>
              </Typography>
              <Typography variant="h5" gutterBottom>
                {selectedReservation.room.name}
              </Typography>
            </Grid>
            <Grid item container xs={12} alignItems="center">
              <Typography variant="h5" gutterBottom color="secondary" style={{ marginRight: 4 }}>
                <b>Tipo de Reserva:</b>
              </Typography>
              <Typography variant="h5" gutterBottom>
                Hora
              </Typography>
            </Grid>
            <Grid item container xs={12} alignItems="center">
              <Typography variant="h5" gutterBottom color="secondary" style={{ marginRight: 4 }}>
                <b>Contrato:</b>
              </Typography>
              <Typography variant="h5" gutterBottom>
                {selectedReservation.contract_id ? 'Sim' : 'Não'}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                name="note"
                value={selectedReservation.note}
                onChange={e => handleChangeNote(e.target.value)}
                variant="outlined"
                placeholder="Nome do paciente ou anotação"
                label="Anotação"
                fullWidth
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {selectedAction === 'update' ? (
          <Button
            disabled={!isCancelable(selectedReservation)}
            onClick={handleDelete}
            className={classes.deleteButton}
            variant="contained"
            style={{ width: 120 }}
            startIcon={<DeleteIcon />}
          >
            Cancelar
          </Button>
        ) : (
          <div />
        )}

        <Button onClick={handleReserve} variant="contained" color="primary" style={{ width: 120 }} startIcon={<CheckIcon />}>
          {selectedReservation && selectedAction === 'create' ? 'Reservar' : 'Atualizar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
