import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { listContractsRequest, setSelectedContract } from '~/store/modules/contract/actions';

import { stringToLongPrettyDate } from '~/services/utils';
import ContractManagementDialog from './Dialog';

const useStyles = makeStyles(() => ({
  root: {
    padding: 12,
  },

  addButton: {
    fontSize: 13,
    width: 150,
  },

  basic: {
    fontSize: 12,
    padding: 5,
    height: 35,
    borderRadius: 10,
    border: '1px solid #DDD',
    textAlign: 'center',
  },
}));

export default function ContractManagement() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { contracts, newContract } = useSelector(state => state.contract);

  const handleAdd = () => {
    dispatch(setSelectedContract(newContract));
  };

  const handleEdit = contract => {
    dispatch(setSelectedContract(contract));
  };

  useEffect(() => {
    dispatch(listContractsRequest());
  }, [dispatch]);

  return (
    <>
      <ContractManagementDialog />

      <Box align="center" className={classes.root}>
        <Box display="flex" justifyContent="space-between" width="100%" mb="12px">
          <Typography variant="h5" style={{ fontWeight: 'bold', lineHeight: '36px' }} color="secondary">
            Contratos
          </Typography>

          <Button onClick={() => handleAdd()} variant="contained" color="primary" className={classes.addButton} startIcon={<AddIcon />}>
            Contrato
          </Button>
        </Box>

        <Table size="small" style={{ maxHeight: 'calc(100vh - 108px)' }}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.basic} style={{ width: '25%', fontWeight: 'bold' }}>
                Usuário
              </TableCell>
              <TableCell className={classes.basic} style={{ width: '25%', fontWeight: 'bold' }} align="center">
                Sala
              </TableCell>
              <TableCell className={classes.basic} style={{ width: '25%', fontWeight: 'bold' }}>
                Data de Início
              </TableCell>
              <TableCell className={classes.basic} style={{ width: '25%', fontWeight: 'bold' }}>
                Data de Término
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {contracts.map(contract => (
              <TableRow key={contract.id} hover onClick={() => handleEdit(contract)} style={{ cursor: 'pointer' }}>
                <TableCell className={classes.basic} style={{ width: '25%' }}>
                  {contract.user.username}
                </TableCell>
                <TableCell className={classes.basic} style={{ width: '25%' }}>
                  {contract.room.name}
                </TableCell>
                <TableCell className={classes.basic} style={{ width: '25%' }} align="center">
                  {stringToLongPrettyDate(contract.start_date)}
                </TableCell>
                <TableCell className={classes.basic} style={{ width: '25%' }}>
                  {stringToLongPrettyDate(contract.end_date)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
}
