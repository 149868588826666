import palette from '../palette';

export default {
  root: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: palette.secondary.main,
      },
    },
    '& .MuiInputLabel-root': {
      '&.Mui-focused': {
        color: palette.secondary.main,
      },
    },
  },
};
