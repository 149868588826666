import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Wrapper } from './styles';
import Sidebar from './Sidebar';
import Header from './Header';

import { setDates } from '~/store/modules/reservation/actions';
import { formatRawDate, formatPrettyDate } from '~/services/utils';
import { listRoomsRequest } from '~/store/modules/room/actions';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 48,
    height: '100%',
    display: 'flex',
  },
  content: {
    height: '100%',
    width: '100%',
    background: theme.palette.background.default,
    overflowY: 'auto',
  },
}));

function getLastMonday() {
  var t = new Date();
  t.setDate(t.getDate() - t.getDay() + 1);
  return t;
}

export default function DashboardLayout({ children }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    const numberOfDays = 4 * 7;
    const startDate = getLastMonday();

    dispatch(listRoomsRequest());

    dispatch(
      setDates(
        [...Array(numberOfDays).keys()].map(shift => {
          const date = new Date(startDate);
          date.setDate(date.getDate() + shift);

          return [formatRawDate(date), formatPrettyDate(date)];
        })
      )
    );
  }, [dispatch]);

  return (
    <Wrapper className={classes.root}>
      <Header />
      <Sidebar />

      <main className={classes.content}>{children}</main>
    </Wrapper>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
