import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { rgba } from 'polished';

import VisibilityOnIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { listUserReservationsByMonthRequest } from '~/store/modules/reservation/actions';
import { stringToPrettyDate, getReservationPrice, formatCurrency, currentMonthAndYear, isPast } from '~/services/utils';

import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    minHeight: 120,
    flexDirection: 'column',
    width: '100%',
    padding: 12,
  },
  icon: {
    color: theme.palette.icon,
  },
  rootList: {
    maxHeight: 'calc(100vh - 274px)',
    overflowY: 'scroll',
    width: '100%',
  },
  listHeader: {
    background: rgba(10, 35, 130, 0.15),
    borderRadius: 4,
    marginTop: 12,
    cursor: 'pointer',
    '&:hover': {
      background: rgba(10, 35, 130, 0.25),
    },
    '-webkit-transition': 'background 0.4s ease-in-out',
    transition: 'background 0.4s ease-in-out',
  },
  nested: {
    borderBottom: `2px solid ${rgba(0, 0, 0, 0.05)}`,
    borderRadius: 4,
    paddingLeft: theme.spacing(2),
    marginTop: 4,
  },
}));

export default function Overview() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { reservationsByMonth } = useSelector(state => state.reservation);

  const [expectedValue, setExpectedValue] = useState(0);
  const [partialValue, setPartialValue] = useState(0);

  const [groupedConsumedReservations, setGroupedConsumedReservations] = useState({});

  const [statementDates, setStatementDates] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [expandedItens, setExpandedItens] = useState({});
  const [currentMonthYear, setCurrentMonthYear] = useState();

  const handleExpansion = date => {
    const newExpandedItens = { ...expandedItens };

    if (date in expandedItens) delete newExpandedItens[date];
    else newExpandedItens[date] = null;

    setExpandedItens(newExpandedItens);
  };

  useEffect(() => {
    setCurrentMonthYear(currentMonthAndYear());
    dispatch(listUserReservationsByMonthRequest());
  }, [dispatch]);

  const groupByDate = arr => {
    return arr.reduce((groupedObj, event) => {
      (groupedObj[event.date] = groupedObj[event.date] || []).push(event);
      return groupedObj;
    }, {});
  };

  useEffect(() => {
    setGroupedConsumedReservations(groupByDate(reservationsByMonth));

    const dates = [];
    reservationsByMonth.forEach(event => {
      dates.push(event.date);
    });

    setStatementDates([...new Set(dates)]);

    setExpectedValue(reservationsByMonth.reduce((acc, { price }) => acc + price, 0.0));

    setPartialValue(
      reservationsByMonth.reduce((acc, { price, date, time }) => {
        if (isPast(date, time)) return acc + price;
        return acc;
      }, 0.0)
    );
  }, [reservationsByMonth]);

  return (
    <Box>
      <Card className={classes.card}>
        <Box display="flex" pr={4} justifyContent="space-between">
          <Typography variant="h5" gutterBottom color="secondary">
            Resumo {currentMonthYear}
          </Typography>
          {isVisible ? (
            <VisibilityOnIcon className={classes.icon} onClick={() => setIsVisible(false)} />
          ) : (
            <VisibilityOffIcon className={classes.icon} onClick={() => setIsVisible(true)} />
          )}
        </Box>

        <Box display="flex" pl={isMobile ? 1 : 4} pr={isMobile ? 1 : 4} justifyContent="space-between">
          <Typography variant="h6" gutterBottom>
            Até o momento:
          </Typography>
          {isVisible ? (
            <Typography variant="h6" gutterBottom>
              R$ {partialValue.toFixed(2)}
            </Typography>
          ) : (
            <Box height={20} width={100} borderRadius={4} style={{ background: rgba(0, 0, 0, 0.1) }} />
          )}
        </Box>
        <Box display="flex" pl={isMobile ? 1 : 4} pr={isMobile ? 1 : 4} justifyContent="space-between">
          <Typography variant="h6" gutterBottom>
            Previsto:
          </Typography>
          {isVisible ? (
            <Typography variant="h6" gutterBottom>
              R$ {expectedValue.toFixed(2)}
            </Typography>
          ) : (
            <Box height={20} width={100} borderRadius={4} style={{ background: rgba(0, 0, 0, 0.1) }} />
          )}
        </Box>
      </Card>
      <Box mt={3}>
        <Card className={classes.card}>
          <Typography variant="h5" color="secondary">
            Fatura {currentMonthYear}
          </Typography>
          <List component="nav" className={classes.rootList}>
            {reservationsByMonth.length === 0 && (
              <Box height={60} display="flex" justifyContent="center" alignItems="center" width="100%">
                <Typography variant="h6">Sem lançamentos até o momento</Typography>
              </Box>
            )}
            {statementDates.map(date => (
              <React.Fragment key={date}>
                <ListItem className={classes.listHeader} onClick={() => handleExpansion(date)}>
                  <ListItemText primary={stringToPrettyDate(date)} />
                  {date in expandedItens ? <ExpandLess /> : <ExpandMore />}
                </ListItem>

                <Collapse in={date in expandedItens} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {date in groupedConsumedReservations &&
                      groupedConsumedReservations[date].map(({ time, note, room, price, shift_id: shiftID }) => (
                        <ListItem key={time} className={classes.nested}>
                          {isMobile && (
                            <Box display="flex" flexDirection="column" width={1}>
                              <Box display="flex" justifyContent="space-between" mb={1}>
                                <Typography variant="body2">{time}</Typography>
                                <Typography variant="body2">{note || '-'}</Typography>
                              </Box>
                              <Box display="flex" justifyContent="space-between">
                                <Typography variant="body2">{room.name}</Typography>
                                <Typography variant="body2" color="secondary">
                                  {formatCurrency(getReservationPrice(room, price, shiftID))}
                                </Typography>
                              </Box>
                            </Box>
                          )}
                          {!isMobile && (
                            <Box display="flex" justifyContent="space-between" width={1}>
                              <Typography variant="body2" style={{ width: 25, textAlign: 'center' }}>
                                {time}
                              </Typography>
                              <Typography
                                variant="body2"
                                style={{
                                  width: 100,
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {room.name}
                              </Typography>
                              <Typography
                                variant="body2"
                                style={{ width: 50, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                              >
                                {note || '-'}
                              </Typography>
                              <Typography variant="body2" style={{ width: 55, textAlign: 'center' }} color="secondary">
                                {isVisible ? (
                                  formatCurrency(price)
                                ) : (
                                  <Box height={15} width={55} borderRadius={4} style={{ background: rgba(0, 0, 0, 0.1) }} />
                                )}
                              </Typography>
                            </Box>
                          )}
                        </ListItem>
                      ))}
                  </List>
                </Collapse>
              </React.Fragment>
            ))}
          </List>
        </Card>
      </Box>
    </Box>
  );
}
