import produce from 'immer';

const defaultNewRoom = {
  name: '',
  hour_price: null,
  shift_hourly_price: null,
  is_active: 1,
};

const INITIAL_STATE = {
  loading: false,
  rooms: [],
  mergeAction: null,
  selectedRoom: null,
};

export default function room(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@room/CLEAR_SELECTED_ROOM': {
        draft.selectedRoom = null;
        draft.mergeAction = null;
        break;
      }

      case '@room/SET_SELECTED_ROOM': {
        draft.selectedRoom = action.payload.selectedRoom;
        draft.mergeAction = 'update';
        break;
      }

      case '@room/NEW_ROOM_SELECTION': {
        draft.selectedRoom = defaultNewRoom;
        draft.mergeAction = 'create';
        break;
      }

      case '@room/UPDATE_SELECTED_ROOM_INFO':
        const { field, value } = action.payload;
        draft.selectedRoom[field] = value;
        break;

      case '@room/LIST_ROOMS_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@room/LIST_ROOMS_SUCCESS': {
        draft.rooms = action.payload.rooms;
        draft.loading = false;
        break;
      }

      case '@room/LIST_ROOMS_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@room/MERGE_ROOM_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@room/MERGE_ROOM_SUCCESS': {
        const { mergedRoom } = action.payload;
        draft.rooms = [mergedRoom, ...state.rooms.filter(r => r.id !== mergedRoom.id)];
        draft.loading = false;
        break;
      }

      case '@room/MERGE_ROOM_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@room/DELETE_ROOM_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@room/DELETE_ROOM_SUCCESS': {
        const { deletedRoom } = action.payload;
        draft.rooms = [deletedRoom, ...state.rooms.filter(r => r.id !== deletedRoom.id)];
        draft.loading = false;
        break;
      }

      case '@room/DELETE_ROOM_FAILURE': {
        draft.loading = false;
        break;
      }

      default:
    }
  });
}
