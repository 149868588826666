import produce from 'immer';

const INITIAL_STATE = {
  menuOpen: false,
  viewMode: 'table',
};

export default function menu(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@env/TOGGLE_MENU':
        draft.menuOpen = !draft.menuOpen;
        break;

      case '@env/SET_VIEW_MODE':
        draft.viewMode = action.payload.viewMode;
        break;

      default:
    }
  });
}
