import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '~/services/api';

import {
  createShiftReservationSuccess,
  createShiftReservationFailure,
  updateShiftReservationFailure,
  updateShiftReservationSuccess,
  deleteShiftReservationSuccess,
  deleteShiftReservationFailure,
} from './actions';

export function* createShiftReservation({ payload }) {
  try {
    const response = yield call(api.post, 'shifts', payload);
    const { status, message, reservations } = response.data;

    if (status === 'ERROR') {
      yield put(createShiftReservationFailure());
      toast.error(message);
    } else {
      yield put(createShiftReservationSuccess(reservations));
    }
  } catch (err) {
    yield put(createShiftReservationFailure());
    toast.error(`Falha ao criar a reserva de turno`);
  }
}

export function* updateShiftReservation({ payload }) {
  const { id, notes } = payload;

  try {
    const response = yield call(api.put, `shifts/${id}`, { notes });
    const { status, message, reservations } = response.data;

    if (status === 'ERROR') {
      yield put(updateShiftReservationFailure());
      toast.error(message);
    } else {
      yield put(updateShiftReservationSuccess(reservations));
    }
  } catch (err) {
    yield put(updateShiftReservationFailure());
    toast.error(`Falha ao atualizar a reserva de turno`);
  }
}

export function* deleteShiftReservation({ payload }) {
  const { shiftReservationID } = payload;

  try {
    const response = yield call(api.delete, `shifts/${shiftReservationID}`);
    const { status, message, shift } = response.data;

    if (status === 'ERROR') {
      yield put(deleteShiftReservationFailure());
      toast.error(message);
    } else {
      yield put(deleteShiftReservationSuccess(shift));
    }
  } catch (err) {
    yield put(deleteShiftReservationFailure());
    toast.error(`Falha ao deletar a reserva de turno`);
  }
}

export default all([
  takeLatest('@shift_reservation/CREATE_SHIFT_RESERVATION_REQUEST', createShiftReservation),
  takeLatest('@shift_reservation/UPDATE_SHIFT_RESERVATION_REQUEST', updateShiftReservation),
  takeLatest('@shift_reservation/DELETE_SHIFT_RESERVATION_REQUEST', deleteShiftReservation),
]);
