import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { rgba } from 'polished';

import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { userListRequest, reservationListRequest } from '~/store/modules/admin/actions';
import TextField from '@material-ui/core/TextField';

import { stringToLongPrettyDate, formatCurrency } from '~/services/utils';

const useStyles = makeStyles(theme => ({
  basic: {
    fontSize: 12,
    padding: 5,
    height: 38,
    border: '1px solid #DDD',
    textAlign: 'center',
  },
  container: {
    height: 'calc(100vh - 215px)',
  },
  tableRow: {
    background: '#FFF',
    '&:hover': {
      background: `${rgba(10, 35, 130, 0.15)} !important`,
      cursor: 'pointer',
    },
  },
}));

export default function ReservationManagement() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { users, reservations } = useSelector(state => state.admin);
  const { rooms } = useSelector(state => state.room);

  const [selectedUser, setSelectedUser] = useState('');
  const [selectedRoom, setSelectedRoom] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleSearch = () => {
    dispatch(reservationListRequest(selectedUser.id, selectedRoom.id, startDate, endDate));
  };

  useEffect(() => {
    dispatch(userListRequest());
  }, [dispatch]);

  return (
    <Box m={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h5" style={{ fontWeight: 'bold', lineHeight: '48px' }} color="secondary">
              Gerência de Reserva
            </Typography>

            <Button
              onClick={() => handleSearch()}
              disabled={!selectedUser && !selectedRoom && !startDate && !endDate}
              variant="contained"
              color="primary"
              width={200}
            >
              Pesquisar
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel id="room">Selecione um usuário</InputLabel>
            <Select name="user_id" value={selectedUser} onChange={e => setSelectedUser(e.target.value)} label="Selecione um Usuário">
              {users.map(option => (
                <MenuItem key={option.id} value={option}>
                  {option.username}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel id="room">Selecione uma sala</InputLabel>
            <Select name="room_id" value={selectedRoom} onChange={e => setSelectedRoom(e.target.value)} label="Selecione uma Sala">
              {rooms.map(option => (
                <MenuItem key={option.id} value={option}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            size="small"
            autoComplete="off"
            name="start_date"
            type="date"
            value={startDate}
            onChange={e => setStartDate(e.target.value)}
            variant="outlined"
            label="Data de Início"
            placeholder="Data de Início"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            size="small"
            autoComplete="off"
            name="end_date"
            type="date"
            value={endDate}
            onChange={e => setEndDate(e.target.value)}
            variant="outlined"
            label="Data de Término"
            placeholder="Data de Término"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={12}>
          <TableContainer className={classes.container}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.basic} style={{ textAlign: 'left', fontWeight: 'bold' }}>
                    Data
                  </TableCell>
                  <TableCell className={classes.basic} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                    Hora
                  </TableCell>
                  <TableCell className={classes.basic} style={{ textAlign: 'left', fontWeight: 'bold' }}>
                    Usuário
                  </TableCell>
                  <TableCell className={classes.basic} style={{ textAlign: 'left', fontWeight: 'bold' }}>
                    Sala
                  </TableCell>
                  <TableCell className={classes.basic} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                    Valor
                  </TableCell>
                  <TableCell className={classes.basic} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                    Data de Criação
                  </TableCell>
                  <TableCell className={classes.basic} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                    Última Atualização
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {reservations.map((reservation, index) => {
                  return (
                    <TableRow hover key={index} className={classes.tableRow}>
                      <TableCell className={classes.basic} style={{ textAlign: 'left' }}>
                        {stringToLongPrettyDate(reservation.date)}
                      </TableCell>
                      <TableCell className={classes.basic} style={{ textAlign: 'center' }}>
                        {reservation.time}
                      </TableCell>
                      <TableCell className={classes.basic} style={{ textAlign: 'left' }}>
                        {reservation.user.username}
                      </TableCell>
                      <TableCell className={classes.basic} style={{ textAlign: 'left' }}>
                        {reservation.room.name}
                      </TableCell>
                      <TableCell className={classes.basic} style={{ textAlign: 'right' }}>
                        {formatCurrency(reservation.price)}
                      </TableCell>
                      <TableCell className={classes.basic} style={{ textAlign: 'center' }}>
                        {reservation.created_at}
                      </TableCell>
                      <TableCell className={classes.basic} style={{ textAlign: 'center' }}>
                        {reservation.updated_at}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
}
