import { cloneObject } from '~/services/utils';

export function clearSelectedRoom() {
  return {
    type: '@room/CLEAR_SELECTED_ROOM',
  };
}

export function setSelectedRoom(selectedRoom, action) {
  return {
    type: '@room/SET_SELECTED_ROOM',
    payload: {
      selectedRoom,
      action,
    },
  };
}

export function newRoomSelection() {
  return {
    type: '@room/NEW_ROOM_SELECTION',
  };
}

export function updateSelectedRoomInfo(field, value) {
  return {
    type: '@room/UPDATE_SELECTED_ROOM_INFO',
    payload: {
      field,
      value,
    },
  };
}

export function mergeRoomRequest(room, action) {
  return {
    type: '@room/MERGE_ROOM_REQUEST',
    payload: {
      room: cloneObject(room),
      action,
    },
  };
}

export function mergeRoomSuccess(mergedRoom) {
  return {
    type: '@room/MERGE_ROOM_SUCCESS',
    payload: {
      mergedRoom,
    },
  };
}

export function mergeRoomFailure() {
  return {
    type: '@room/MERGE_ROOM_FAILURE',
  };
}

export function deleteRoomRequest(roomId) {
  return {
    type: '@room/DELETE_ROOM_REQUEST',
    payload: {
      roomId,
    },
  };
}

export function deleteRoomSuccess(deletedRoom) {
  return {
    type: '@room/DELETE_ROOM_SUCCESS',
    payload: {
      deletedRoom,
    },
  };
}

export function deleteRoomFailure() {
  return {
    type: '@room/DELETE_ROOM_FAILURE',
  };
}

export function listRoomsRequest() {
  return {
    type: '@room/LIST_ROOMS_REQUEST',
  };
}

export function listRoomsSuccess(rooms) {
  return {
    type: '@room/LIST_ROOMS_SUCCESS',
    payload: {
      rooms,
    },
  };
}

export function listRoomsFailure() {
  return {
    type: '@room/LIST_ROOMS_FAILURE',
  };
}
