import React from 'react';
import { Link } from 'react-router-dom';
import logo from '~/assets/logo.svg';
import { Box, Card, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';

const useStyles = makeStyles(theme => ({
  card: {
    padding: 16,
    borderRadius: 12,
    width: '50%',
    maxWidth: 700,
    minWidth: 315,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textBox: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  linkText: {
    marginLeft: 4,
    fontWeight: 'bold',
  },
}));

export default function AfterSignUp() {
  const classes = useStyles();

  return (
    <Card className={classes.card} elevation={5}>
      <img src={logo} alt="Viver Integral Coworking" style={{ width: '80%', marginTop: 12 }} />
      <Box mt={4} width="90%">
        <Typography align="center" variant="h5" gutterBottom color="secondary">
          Usuário cadastrado com sucesso!
        </Typography>
        <Typography align="center" variant="h6" gutterBottom>
          Nossa equipe vai avaliá-lo e em breve entrará em contato com você, combinado?
        </Typography>

        <Typography align="center" variant="h6" gutterBottom>
          Enquanto isso, você pode continuar navegando pelo nosso site ou ver os conteúdos que compartilhamos em nossas redes sociais. O
          canal do Youtube conta com diversos vídeos e meditações. Confira abaixo!
        </Typography>

        <IconButton
          color="primary"
          style={{ marginRight: 12 }}
          onClick={() => window.location.replace('https://www.facebook.com/ViverIntegralCoworking')}
        >
          <FacebookIcon fontSize="large" />
        </IconButton>

        <IconButton
          color="primary"
          style={{ marginRight: 12 }}
          onClick={() => window.location.replace('https://www.instagram.com/viverintegralcoworking/')}
        >
          <InstagramIcon fontSize="large" />
        </IconButton>

        <IconButton color="primary" onClick={() => window.location.replace('https://www.youtube.com/channel/UChJM-0kVHjAmAvQpTbLRVGQ')}>
          <YouTubeIcon fontSize="large" />
        </IconButton>
      </Box>

      <Box display="flex" justifyContent="center" mt={2} className={classes.textBox}>
        <Typography variant="h6">Seu cadastro já foi aprovado?</Typography>

        <Link to="/">
          <Typography variant="h6" color="secondary" className={classes.linkText}>
            Acesse aqui!
          </Typography>
        </Link>
      </Box>
    </Card>
  );
}
