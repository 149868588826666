import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';

import { Box, Typography, Button, IconButton, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import {
  clearSelectedStatement,
  createStatementRequest,
  updateStatementRequest,
  deleteStatementRequest,
  updateSelectedStatementInfo,
  listStatementReservationsRequest,
} from '~/store/modules/statement/actions';

import { formatCurrency, stringToPrettyDate } from '~/services/utils';
import { userListRequest } from '~/store/modules/admin/actions';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    padding: '16px 16px 8px 16px',
  },

  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 16px 16px 16px',
  },

  deleteButton: {
    width: 120,
    background: '#FFF',
    color: theme.palette.error.main,
    boxShadow: 'none',
    '&:hover': {
      color: theme.palette.error.contrastText,
      background: theme.palette.error.dark,
    },
  },

  basic: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 12,
    border: '3px solid #F9F9F9',
    textAlign: 'center',
    lineHeight: '17px',
  },
}));

export default function StatementManagementDialog() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { users } = useSelector(state => state.admin);
  const { selectedStatement } = useSelector(state => state.statement);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleClose = () => {
    dispatch(clearSelectedStatement());
  };

  const handleCreate = () => {
    const updatedStatement = _.cloneDeep(selectedStatement);

    updatedStatement.total_price = selectedStatement.reservations.reduce((acc, pos) => {
      return acc + pos.final_price;
    }, 0.0);

    dispatch(createStatementRequest(updatedStatement));
    handleClose();
  };

  const handleDelete = statementID => {
    dispatch(deleteStatementRequest(statementID));
  };

  const handleUpdate = statementID => {
    dispatch(updateStatementRequest(statementID));
    handleClose();
  };

  const handleChange = (field, value) => {
    dispatch(updateSelectedStatementInfo(field, value));
  };

  const handleEditDiscount = (index, value) => {
    const updatedReservations = _.cloneDeep(selectedStatement.reservations);

    updatedReservations.forEach((reservation, i) => {
      if (i === index) reservation.discount = value;
      reservation.final_price = reservation.price - reservation.discount;
    });

    dispatch(updateSelectedStatementInfo('reservations', updatedReservations));
  };

  const handleListReservations = () => {
    dispatch(listStatementReservationsRequest(selectedStatement.user_id, startDate, endDate));
  };

  const isInvalidStatement = () => {
    if (!selectedStatement?.user_id) return true;
    if (!selectedStatement?.description) return true;
    if (!selectedStatement?.due_date) return true;
    if (!selectedStatement?.reservations) return true;

    return false;
  };

  const getReservationType = reservation => {
    const { contract_id: contractID, shift_id: shiftID } = reservation;

    if (contractID) return 'Contrato';
    if (shiftID) return 'Turno Avulso';
    return 'Hora Avulsa';
  };

  useEffect(() => {
    dispatch(userListRequest());
  }, [dispatch]);

  return (
    <Dialog open={!!selectedStatement} onClose={handleClose} maxWidth="md" fullWidth>
      <Box className={classes.dialogTitle} display="flex" justifyContent="space-between" width="100%">
        <Typography variant="h4">
          <b>Fatura</b>
        </Typography>
        <IconButton size="small" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        {selectedStatement && (
          <Grid container spacing={2}>
            <Grid item xs={selectedStatement.id ? 4 : 3}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel id="room">Selecione um usuário</InputLabel>
                <Select
                  name="user_id"
                  value={selectedStatement.user_id}
                  onChange={e => handleChange(e.target.name, e.target.value)}
                  label="Selecione um Usuário"
                  disabled={selectedStatement.id}
                >
                  {users.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.username}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {!selectedStatement.id && (
              <Grid item xs={3}>
                <TextField
                  size="small"
                  fullWidth
                  autoComplete="off"
                  name="start_date"
                  type="date"
                  value={selectedStatement.start_date}
                  onChange={e => setStartDate(e.target.value)}
                  variant="outlined"
                  label="Data de Início"
                  placeholder="Data de Início"
                  InputLabelProps={{ shrink: true }}
                  disabled={selectedStatement.id}
                />
              </Grid>
            )}

            {!selectedStatement.id && (
              <Grid item xs={3}>
                <TextField
                  size="small"
                  fullWidth
                  autoComplete="off"
                  name="end_date"
                  type="date"
                  value={selectedStatement.end_date}
                  onChange={e => setEndDate(e.target.value)}
                  variant="outlined"
                  label="Data de Término"
                  placeholder="Data de Término"
                  disabled={selectedStatement.id}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            )}

            {!selectedStatement.id && (
              <Grid container item xs={3} alignItems="center" justifyContent="center">
                <Button
                  onClick={handleListReservations}
                  disabled={!selectedStatement?.user_id}
                  variant="contained"
                  color="primary"
                  style={{ width: 180 }}
                  startIcon={<CheckIcon />}
                >
                  Listar Reservas
                </Button>
              </Grid>
            )}

            <Grid item xs={selectedStatement.id ? 4 : 6}>
              <TextField
                fullWidth
                size="small"
                autoComplete="off"
                name="due_date"
                type="date"
                value={selectedStatement.due_date}
                onChange={e => handleChange(e.target.name, e.target.value)}
                variant="outlined"
                label="Data de Vencimento"
                placeholder="Data de Vencimento"
                disabled={selectedStatement.id}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={selectedStatement.id ? 4 : 6}>
              <TextField
                autoComplete="off"
                size="small"
                name="description"
                value={selectedStatement.description}
                onChange={e => handleChange(e.target.name, e.target.value)}
                variant="outlined"
                placeholder="Descrição"
                label="Descrição"
                fullWidth
                disabled={selectedStatement.id}
              />
            </Grid>

            <Grid item xs={12}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.basic} style={{ width: '25%', fontWeight: 'bold' }}>
                      Data
                    </TableCell>
                    <TableCell className={classes.basic} style={{ width: '25%', fontWeight: 'bold' }}>
                      Hora
                    </TableCell>
                    <TableCell className={classes.basic} style={{ width: '25%', fontWeight: 'bold', textAlign: 'left' }}>
                      Sala
                    </TableCell>
                    <TableCell className={classes.basic} style={{ width: '25%', fontWeight: 'bold', textAlign: 'left' }}>
                      Tipo de Reserva
                    </TableCell>
                    <TableCell className={classes.basic} style={{ width: '25%', fontWeight: 'bold' }}>
                      Preço Original
                    </TableCell>
                    <TableCell className={classes.basic} style={{ width: '25%', fontWeight: 'bold' }}>
                      Desconto
                    </TableCell>
                    <TableCell className={classes.basic} style={{ width: '25%', fontWeight: 'bold' }}>
                      Preço Final
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {selectedStatement.reservations.map((reservation, index) => (
                    <TableRow key={index} hover>
                      <TableCell className={classes.basic} style={{ width: '25%', fontWeight: 'bold' }}>
                        {stringToPrettyDate(reservation.date)}
                      </TableCell>
                      <TableCell className={classes.basic} style={{ width: '25%', fontWeight: 'bold' }}>
                        {reservation.time}
                      </TableCell>
                      <TableCell className={classes.basic} style={{ width: '25%', textAlign: 'left' }}>
                        {reservation.room.name}
                      </TableCell>
                      <TableCell className={classes.basic} style={{ width: '25%', textAlign: 'left' }}>
                        {getReservationType(reservation)}
                      </TableCell>
                      <TableCell className={classes.basic} style={{ width: '25%', textAlign: 'right' }}>
                        {formatCurrency(reservation.price)}
                      </TableCell>
                      <TableCell className={classes.basic} style={{ width: '25%', textAlign: 'right' }}>
                        {selectedStatement.id ? (
                          formatCurrency(reservation.discount)
                        ) : (
                          <TextField size="small" value={reservation.discount} onChange={e => handleEditDiscount(index, e.target.value)} />
                        )}
                      </TableCell>
                      <TableCell className={classes.basic} style={{ width: '25%', textAlign: 'right' }}>
                        {formatCurrency(reservation.final_price)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        {selectedStatement && selectedStatement.id ? (
          <Box flexGrow={1} display={'flex'} justifyContent={'flex-end'}>
            <Button
              onClick={() => handleDelete(selectedStatement.id)}
              className={classes.deleteButton}
              variant="contained"
              startIcon={<DeleteIcon />}
            >
              Deletar
            </Button>

            <Button
              onClick={() => handleUpdate(selectedStatement.id)}
              variant="contained"
              color="primary"
              style={{ width: 200, marginLeft: 16, marginRight: 16 }}
              startIcon={<CheckIcon />}
              disabled={selectedStatement.status !== 'PAYMENT_PENDING'}
            >
              Confirmar Pgto
            </Button>
          </Box>
        ) : (
          <Box flexGrow={1} display={'flex'} justifyContent={'flex-end'}>
            <Button
              onClick={handleCreate}
              disabled={isInvalidStatement()}
              variant="contained"
              color="primary"
              style={{ width: 120, marginRight: 16 }}
              startIcon={<CheckIcon />}
            >
              Criar
            </Button>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
}
