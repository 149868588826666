import produce from 'immer';

const INITIAL_STATE = {
  loading: false,

  statements: [],
  userStatements: [],
  selectedStatement: null,

  newStatement: {
    user_id: '',
    description: '',
    due_date: '',
    reservations: [],
  },
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@statement/LIST_STATEMENT_RESERVATIONS_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@statement/LIST_STATEMENT_RESERVATIONS_SUCCESS': {
        const { reservations } = action.payload;
        draft.selectedStatement.reservations = reservations;
        draft.loading = false;
        break;
      }

      case '@statement/LIST_USER_STATEMENT_RESERVATIONS_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@statement/LIST_USER_STATEMENTS_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@statement/LIST_USER_STATEMENTS_SUCCESS': {
        draft.userStatements = action.payload.statements;
        draft.loading = false;
        break;
      }

      case '@statement/LIST_USER_STATEMENTS_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@statement/LIST_STATEMENTS_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@statement/LIST_STATEMENTS_SUCCESS': {
        draft.statements = action.payload.statements;
        draft.loading = false;
        break;
      }

      case '@statement/LIST_STATEMENTS_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@statement/CLEAR_SELECTED_STATEMENT': {
        draft.selectedStatement = null;
        break;
      }

      case '@statement/SET_SELECTED_STATEMENT': {
        draft.selectedStatement = action.payload.selectedStatement;
        break;
      }

      case '@statement/UPDATE_SELECTED_STATEMENT_INFO': {
        const { field, value } = action.payload;
        draft.selectedStatement[field] = value;
        break;
      }

      case '@statement/CREATE_STATEMENT_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@statement/CREATE_STATEMENT_SUCCESS': {
        const { statement } = action.payload;
        draft.statements = [statement, ...state.statements.filter(c => c.id !== statement.id)];
        draft.loading = false;
        break;
      }

      case '@statement/CREATE_STATEMENT_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@statement/UPDATE_STATEMENT_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@statement/UPDATE_STATEMENT_SUCCESS': {
        const { statement } = action.payload;
        draft.statements = [statement, ...state.statements.filter(c => c.id !== statement.id)];
        draft.loading = false;
        break;
      }

      case '@statement/UPDATE_STATEMENT_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@statement/DELETE_STATEMENT_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@statement/DELETE_STATEMENT_SUCCESS': {
        const { deletedStatement } = action.payload;
        draft.statements = [...state.statements.filter(c => c.id !== deletedStatement.id)];
        draft.loading = false;
        break;
      }

      case '@statement/DELETE_STATEMENT_FAILURE': {
        draft.loading = false;
        break;
      }

      default:
    }
  });
}
