export default {
  contained: {
    backgroundColor: '#009b9f',
    '&:hover': {
      backgroundColor: '#008185',
    },
    height: 36,
    borderRadius: 40,
    transition: 'background 0.2s',
    color: '#fff',
    textTransform: 'none',
  },
};
