export function clearSelectedUser() {
  return {
    type: '@admin/CLEAR_SELECTED_USER',
  };
}

export function setSelectedUser(selectedUser) {
  return {
    type: '@admin/SET_SELECTED_USER',
    payload: {
      selectedUser,
    },
  };
}

export function updateSelectedUserInfo(field, value) {
  return {
    type: '@admin/UPDATE_SELECTED_USER_INFO',
    payload: {
      field,
      value,
    },
  };
}

export function userListRequest() {
  return {
    type: '@admin/USER_LIST_REQUEST',
    payload: {},
  };
}

export function userListSuccess(users) {
  return {
    type: '@admin/USER_LIST_SUCCESS',
    payload: {
      users,
    },
  };
}

export function userListFailure(userListError) {
  return {
    type: '@admin/USER_LIST_FAILURE',
    payload: {
      userListError,
    },
  };
}

export function userUpdateByAdminRequest(user) {
  return {
    type: '@admin/USER_UPDATE_BY_ADMIN_REQUEST',
    payload: {
      user,
    },
  };
}

export function userUpdateByAdminSuccess(user) {
  return {
    type: '@admin/USER_UPDATE_BY_ADMIN_SUCCESS',
    payload: {
      user,
    },
  };
}

export function userUpdateByAdminFailure() {
  return {
    type: '@admin/USER_UPDATE_BY_ADMIN_FAILURE',
  };
}

export function cleanReset() {
  return {
    type: '@admin/CLEAN_RESET',
  };
}

export function clearReservationListRequest() {
  return {
    type: '@admin/CLEAR_RESERVATIONS',
    payload: {},
  };
}

export function reservationListRequest(userID, roomID, startDate, endDate) {
  return {
    type: '@admin/RESERVATION_LIST_REQUEST',
    payload: {
      userID,
      roomID,
      startDate,
      endDate,
    },
  };
}

export function reservationListSuccess(reservations) {
  return {
    type: '@admin/RESERVATION_LIST_SUCCESS',
    payload: {
      reservations: reservations,
    },
  };
}

export function reservationListFailure() {
  return {
    type: '@admin/RESERVATION_LIST_FAILURE',
    payload: {},
  };
}

export function clearRoomReservationListRequest() {
  return {
    type: '@admin/CLEAR_ROOM_RESERVATIONS',
    payload: {},
  };
}

export function roomReservationListRequest(roomID, startDate, endDate) {
  return {
    type: '@admin/ROOM_RESERVATION_LIST_REQUEST',
    payload: {
      roomID,
      startDate,
      endDate,
    },
  };
}

export function roomReservationListSuccess(roomReservations) {
  return {
    type: '@admin/ROOM_RESERVATION_LIST_SUCCESS',
    payload: {
      roomReservations: roomReservations,
    },
  };
}

export function roomReservationListFailure(roomReservationListError) {
  return {
    type: '@admin/ROOM_RESERVATION_LIST_FAILURE',
    payload: {
      roomListError: roomReservationListError,
    },
  };
}
