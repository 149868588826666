import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  html, body, #root{
    height: 100%;
    background: '#F9F9F9';
    overflow-x: hidden;
  }

  html{
    overflow-y: hidden;
  }

  body{
    -webkit-font-smoothing: antialiased;
  }

  body, input, button{
    font: 14px, 'Roboto', sans-serif;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }


  *::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: transparent;
  }

  *::-webkit-scrollbar-corner {
    background: transparent;
  }
  
  *::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: #959595;
  }

  *:hover::-webkit-scrollbar-thumb {
    background-color: #959595;
  }

  *::-webkit-scrollbar-thumb:active {
    background-color: '#009B9F';
  }
  
  *::-webkit-scrollbar-track {
    background-color: '#FFF';
    border-radius: 50px;
  }

  *::-moz-selection {
    color: '#FFF';
    background: '#009B9F';
  }
  
  *::selection {
    color: '#FFF';
    background: '#009B9F';
  }
`;
