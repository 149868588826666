export function toggleMenu() {
  return {
    type: '@env/TOGGLE_MENU',
  };
}

export function setViewMode(viewMode) {
  return {
    type: '@env/SET_VIEW_MODE',
    payload: {
      viewMode,
    },
  };
}
