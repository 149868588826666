import produce from 'immer';

const defaultProfile = {
  defaultRoom: null,
  enabledRooms: {
    lower_room: true,
    middle_room: true,
    upper_room: true,
    lower_salon: false,
    upper_salon: false,
  },
};

const INITIAL_STATE = {
  loading: false,
  localProfiles: {},
  loggedUser: null,
  profile: null,
  registerError: null,
  fieldErrors: {},
  forgotPasswordError: null,
  passwordResetError: null,
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@user/SIGN_UP_REQUEST': {
        draft.loading = true;
        draft.registerError = null;
        break;
      }

      case '@user/SIGN_UP_SUCCESS': {
        draft.loading = false;
        draft.registerError = null;
        break;
      }

      case '@user/SIGN_UP_FAILURE': {
        draft.loading = false;
        draft.registerError = action.payload.registerError;
        draft.fieldErrors = action.payload.fieldErrors;
        break;
      }

      case '@user/FORGOT_PASSWORD_REQUEST': {
        draft.loading = true;
        draft.forgotPasswordError = null;
        break;
      }

      case '@user/FORGOT_PASSWORD_SUCCESS': {
        draft.loading = false;
        draft.forgotPasswordError = null;
        break;
      }

      case '@user/FORGOT_PASSWORD_FAILURE': {
        draft.loading = false;
        draft.forgotPasswordError = action.payload.forgotPasswordError;
        break;
      }

      case '@user/PASSWORD_RESET_REQUEST': {
        draft.loading = true;
        draft.passwordResetError = null;
        break;
      }

      case '@user/PASSWORD_RESET_SUCCESS': {
        draft.loading = false;
        draft.passwordResetError = null;
        break;
      }

      case '@user/PASSWORD_RESET_FAILURE': {
        draft.loading = false;
        draft.passwordResetError = action.payload.passwordResetError;
        break;
      }

      case '@user/CLEAR_ERROR': {
        draft.registerError = null;
        draft.forgotPasswordError = null;
        draft.passwordResetError = null;
        draft.fieldErrors = {};
        break;
      }

      case '@auth/SIGN_IN_SUCCESS': {
        const { email } = action.payload.user;
        draft.loggedUser = email;

        if (email in state.localProfiles) {
          draft.profile = state.localProfiles[email];
        } else {
          draft.localProfiles[email] = defaultProfile;
          draft.profile = draft.localProfiles[email];
        }

        break;
      }

      case '@auth/SIGN_OUT': {
        draft.profile = null;
        draft.loggedUser = null;
        break;
      }

      case '@user/UPDATE_PROFILE_INFO': {
        const { key, value } = action.payload;
        draft.profile[key] = value;
        draft.localProfiles[state.loggedUser][key] = value;
        break;
      }

      case '@user/CHANGE_PASSWORD_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@user/CHANGE_PASSWORD_SUCCESS': {
        draft.loading = false;
        break;
      }

      case '@user/CHANGE_PASSWORD_FAILURE': {
        draft.loading = false;
        break;
      }

      default:
    }
  });
}
