import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '~/services/api';

import {
  clearSelectedStatement,
  listStatementsSuccess,
  listStatementsFailure,
  listUserStatementsSuccess,
  listUserStatementsFailure,
  createStatementSuccess,
  createStatementFailure,
  updateStatementSuccess,
  updateStatementFailure,
  deleteStatementSuccess,
  deleteStatementFailure,
  listStatementReservationsSuccess,
  listStatementReservationsFailure,
} from './actions';

export function* createStatement({ payload }) {
  try {
    const response = yield call(api.post, 'statements', payload);

    const { statement } = response.data;
    yield put(createStatementSuccess(statement));
    yield put(clearSelectedStatement());
  } catch (err) {
    yield put(createStatementFailure());

    const message = err?.response?.data?.message;
    toast.error(message || `Falha ao criar a fatura`);
  }
}

export function* updateStatement({ payload }) {
  const { statementID } = payload;

  try {
    const response = yield call(api.put, `statements/${statementID}`, {});

    const { statement } = response.data;
    yield put(updateStatementSuccess(statement));
    yield put(clearSelectedStatement());
  } catch (err) {
    yield put(updateStatementFailure());

    const message = err?.response?.data?.message;
    toast.error(message || `Falha ao criar a fatura`);
  }
}

export function* deleteStatement({ payload }) {
  const { statementID } = payload;
  try {
    const response = yield call(api.delete, `statements/${statementID}`);

    const { statement } = response.data;

    yield put(deleteStatementSuccess(statement));
    yield put(clearSelectedStatement());
  } catch (err) {
    yield put(deleteStatementFailure());
    toast.error(`Falha ao deletar a fatura`);
  }
}

export function* listStatements() {
  try {
    const response = yield call(api.get, '/statements/admin');

    const { statements } = response.data;

    yield put(listStatementsSuccess(statements));
  } catch (err) {
    yield put(listStatementsFailure());
    toast.error('Falha ao buscar as faturas');
  }
}

export function* listUserStatements() {
  try {
    const response = yield call(api.get, '/statements');

    const { statements } = response.data;

    yield put(listUserStatementsSuccess(statements));
  } catch (err) {
    yield put(listUserStatementsFailure());
    toast.error('Falha ao buscar as faturas');
  }
}

export function* listStatementReservations({ payload }) {
  const { userID, startDate, endDate } = payload;

  try {
    const response = yield call(api.get, 'reservations/admin', {
      params: {
        user_id: userID,
        start_date: startDate,
        end_date: endDate,
        statement_id: 0,
      },
    });

    const { reservations } = response.data;

    yield put(listStatementReservationsSuccess(reservations));
  } catch (err) {
    yield put(listStatementReservationsFailure());

    const message = err?.response?.data?.message;
    toast.error(message || `Falha ao listar reservas`);
  }
}

export default all([
  takeLatest('@statement/LIST_STATEMENT_RESERVATIONS_REQUEST', listStatementReservations),
  takeLatest('@statement/CREATE_STATEMENT_REQUEST', createStatement),
  takeLatest('@statement/UPDATE_STATEMENT_REQUEST', updateStatement),
  takeLatest('@statement/DELETE_STATEMENT_REQUEST', deleteStatement),
  takeLatest('@statement/LIST_STATEMENTS_REQUEST', listStatements),
  takeLatest('@statement/LIST_USER_STATEMENTS_REQUEST', listUserStatements),
]);
