import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '~/services/api';
import history from '~/services/history';

import {
  signUpSuccess,
  signUpFailure,
  changePasswordSuccess,
  changePasswordFailure,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  passwordResetFailure,
  passwordResetSuccess,
} from './actions';

const sanitizeNumberMask = number => {
  return number.replace(/[^0-9]/g, '');
};

function isInvalidEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !re.test(email);
}

const isInvalidCPF = cpf => {
  return cpf.length !== 11;
};

const isInvalidPhoneNumber = phoneNumber => {
  return phoneNumber.length !== 11;
};

export function* signUp({ payload }) {
  try {
    const { username, email, password, confirmPassword, cpf, rg, phoneNumber, address, profession, licenseNumber, useTerms } = payload;
    const fieldErrors = {};

    let sanitizedCPF;
    let sanitizedPhoneNumber;

    if (!useTerms) {
      fieldErrors.useTerms = 'Você deve aceitar aos termos de uso';
    }

    if (!username) {
      fieldErrors.username = 'Este campo é obrigatório';
    }

    if (!email) {
      fieldErrors.email = 'Este campo é obrigatório';
    } else if (isInvalidEmail(email)) {
      fieldErrors.email = 'Email inválido';
    }

    if (!password) {
      fieldErrors.password = 'Este campo é obrigatório';
    } else if (password.length < 6) {
      fieldErrors.password = 'A senha deve ter no mínimo 6 caracteres';
    }

    if (!confirmPassword) {
      fieldErrors.confirmPassword = 'Este campo é obrigatório';
    } else if (password !== confirmPassword) {
      fieldErrors.confirmPassword = 'As senhas devem ser iguais';
    }

    if (!cpf) {
      fieldErrors.cpf = 'Este campo é obrigatório';
    } else {
      sanitizedCPF = sanitizeNumberMask(cpf);
      if (isInvalidCPF(sanitizedCPF)) {
        fieldErrors.cpf = 'CPF inválido';
      }
    }

    if (!rg) {
      fieldErrors.rg = 'Este campo é obrigatório';
    }

    if (!phoneNumber) {
      fieldErrors.phoneNumber = 'Este campo é obrigatório';
    } else {
      sanitizedPhoneNumber = sanitizeNumberMask(phoneNumber);
      if (isInvalidPhoneNumber(sanitizedPhoneNumber)) {
        fieldErrors.phoneNumber = 'Telefone inválido';
      }
    }

    if (!address) {
      fieldErrors.address = 'Este campo é obrigatório';
    }

    if (!profession) {
      fieldErrors.profession = 'Este campo é obrigatório';
    }

    if (Object.keys(fieldErrors).length > 0) {
      yield put(signUpFailure('', fieldErrors));
    } else {
      yield call(api.post, 'users', {
        username,
        email,
        password,
        cpf: sanitizedCPF,
        rg,
        phone_number: sanitizedPhoneNumber,
        address,
        profession,
        license_number: licenseNumber,
      });

      yield put(signUpSuccess());
      history.push('/register_success');
      toast.success('Usuário criado com sucesso');
    }
  } catch (err) {
    const message = err?.response?.data?.message;

    yield put(signUpFailure(message || 'Falha ao se registar', {}));
  }
}

export function* changePassword({ payload }) {
  try {
    const { currentPassword, newPassword } = payload;

    yield call(api.put, 'users/change_password', {
      currentPassword,
      newPassword,
    });

    toast.success('Senha alterada com sucesso');
    yield put(changePasswordSuccess());
  } catch (err) {
    toast.error('Falha ao alterar a senha');
    yield put(changePasswordFailure());
  }
}

export function* forgotPassword({ payload }) {
  try {
    const { email } = payload;

    if (!email) {
      yield put(forgotPasswordFailure('Preencha o email'));
    } else {
      yield call(api.post, 'users/forgot_password', { email });

      yield put(forgotPasswordSuccess());

      history.push('/');
      toast.success('Solicitação realizada com sucesso, verfique seu email.');
    }
  } catch (err) {
    const message = err?.response?.data?.message;

    yield put(forgotPasswordFailure(message || 'Falha ao solicitar redefinição de senha'));
  }
}

export function* passwordReset({ payload }) {
  try {
    const { token, password, confirmPassword } = payload;

    if (!password || !confirmPassword) {
      yield put(passwordResetFailure('Preencha as duas senhas'));
    } else if (password !== confirmPassword) {
      yield put(passwordResetFailure('As senhas não são iguais'));
    } else {
      yield call(api.put, `users/reset_password/${token}`, { new_password: password });

      yield put(passwordResetSuccess());

      history.push('/');
      toast.success('Senha alterada com sucesso');
    }
  } catch (err) {
    const message = err?.response?.data?.message;

    yield put(passwordResetFailure(message || 'Falha ao redefinir a senha'));
  }
}

export default all([
  takeLatest('@user/SIGN_UP_REQUEST', signUp),
  takeLatest('@user/CHANGE_PASSWORD_REQUEST', changePassword),
  takeLatest('@user/FORGOT_PASSWORD_REQUEST', forgotPassword),
  takeLatest('@user/PASSWORD_RESET_REQUEST', passwordReset),
]);
