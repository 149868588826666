import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  contracts: [],
  selectedContract: null,
  checkedReservations: null,
  lockedTimes: null,
  newContract: {
    user_id: '',
    room_id: '',
    contract_type: '',
    price: '',
    start_date: '',
    end_date: '',
    reservations_config: {
      0: [],
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
    },
  },
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@contract/LIST_CONTRACTS_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@contract/LIST_CONTRACTS_SUCCESS': {
        draft.contracts = action.payload.contracts;
        draft.loading = false;
        break;
      }

      case '@contract/LIST_CONTRACTS_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@contract/CLEAR_SELECTED_CONTRACT': {
        draft.selectedContract = null;
        break;
      }

      case '@contract/SET_SELECTED_CONTRACT': {
        draft.selectedContract = action.payload.selectedContract;
        break;
      }

      case '@contract/UPDATE_SELECTED_CONTRACT_INFO': {
        const { field, value } = action.payload;
        draft.selectedContract[field] = value;
        break;
      }

      case '@contract/FETCH_LOCKED_TIMES_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@contract/FETCH_LOCKED_TIMES_SUCCESS': {
        const { lockedTimes } = action.payload;
        draft.lockedTimes = lockedTimes;
        draft.loading = false;
        break;
      }

      case '@contract/FETCH_LOCKED_TIMES_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@contract/CLEAR_LOCKED_TIMES': {
        draft.lockedTimes = null;
        break;
      }

      case '@contract/CHECK_CONTRACT_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@contract/CHECK_CONTRACT_SUCCESS': {
        const { reservations } = action.payload;
        draft.checkedReservations = reservations;
        draft.loading = false;
        break;
      }

      case '@contract/CHECK_CONTRACT_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@contract/CLEAR_CHECKED_RESERVATIONS': {
        draft.checkedReservations = null;
        break;
      }

      case '@contract/CREATE_CONTRACT_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@contract/CREATE_CONTRACT_SUCCESS': {
        const { contract } = action.payload;
        draft.contracts = [contract, ...state.contracts.filter(c => c.id !== contract.id)];
        draft.loading = false;
        break;
      }

      case '@contract/CREATE_CONTRACT_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@contract/DELETE_CONTRACT_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@contract/DELETE_CONTRACT_SUCCESS': {
        const { deletedContract } = action.payload;
        draft.contracts = [...state.contracts.filter(c => c.id !== deletedContract.id)];
        draft.loading = false;
        break;
      }

      case '@contract/DELETE_CONTRACT_FAILURE': {
        draft.loading = false;
        break;
      }

      default:
    }
  });
}
