import { toast } from 'react-toastify';
import { takeLatest, call, put, all } from 'redux-saga/effects';
import api from '~/services/api';

import {
  userListSuccess,
  userListFailure,
  reservationListSuccess,
  reservationListFailure,
  userUpdateByAdminSuccess,
  userUpdateByAdminFailure,
  roomReservationListSuccess,
  roomReservationListFailure,
} from './actions';

export function* userList() {
  try {
    const response = yield call(api.get, 'users');

    const { users } = response.data;

    yield put(userListSuccess(users));
  } catch (err) {
    const message = err?.response?.data?.message;

    yield put(userListFailure(message || 'Falha ao listar usuários'));
  }
}

export function* reservationList({ payload }) {
  const { userID, roomID, startDate, endDate } = payload;

  try {
    const response = yield call(api.get, 'reservations/admin', {
      params: {
        user_id: userID,
        room_id: roomID,
        start_date: startDate,
        end_date: endDate,
      },
    });

    const { reservations } = response.data;

    yield put(reservationListSuccess(reservations));
  } catch (err) {
    yield put(reservationListFailure());

    const message = err?.response?.data?.message;
    toast.error(message || `Falha ao listar reservas`);
  }
}

export function* roomReservationList({ payload }) {
  const { roomID, startDate, endDate } = payload;

  try {
    const response = yield call(api.get, 'room_reservations', {
      params: {
        room_id: roomID,
        start_date: startDate,
        end_date: endDate,
      },
    });

    const { roomReservations } = response.data;

    yield put(roomReservationListSuccess(roomReservations));
  } catch (err) {
    const message = err?.response?.data?.message;

    yield put(roomReservationListFailure(message || 'Falha ao listar reservas da sala'));
  }
}

export function* updateUser({ payload }) {
  try {
    const { user } = payload;

    const response = yield call(api.put, `users/${user.id}/admin`, user);

    const { user: updatedUser } = response.data;

    yield put(userUpdateByAdminSuccess(updatedUser));
  } catch (err) {
    const message = err?.response?.data?.message;

    yield put(userUpdateByAdminFailure(message || 'Falha ao atualizar o usuário'));
  }
}

export default all([
  takeLatest('@admin/USER_LIST_REQUEST', userList),
  takeLatest('@admin/RESERVATION_LIST_REQUEST', reservationList),
  takeLatest('@admin/ROOM_RESERVATION_LIST_REQUEST', roomReservationList),
  takeLatest('@admin/USER_UPDATE_BY_ADMIN_REQUEST', updateUser),
]);
