import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import DialogContent from '@material-ui/core/DialogContent';

import CloseIcon from '@material-ui/icons/Close';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { clearSelectedStatement } from '~/store/modules/statement/actions';

import { formatCurrency, stringToPrettyDate } from '~/services/utils';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    padding: '24px 24px 8px 24px',
  },

  basic: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 12,
    border: '3px solid #F9F9F9',
    textAlign: 'center',
    lineHeight: '17px',
  },
}));

export default function StatementManagementDialog() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { selectedStatement } = useSelector(state => state.statement);

  const handleClose = () => {
    dispatch(clearSelectedStatement());
  };

  const getReservationType = reservation => {
    const { contract_id: contractID, shift_id: shiftID } = reservation;

    if (contractID) return 'Contrato';
    if (shiftID) return 'Turno Avulso';
    return 'Hora Avulsa';
  };

  return (
    <Dialog open={!!selectedStatement} onClose={handleClose} maxWidth="md" fullWidth>
      <Box className={classes.dialogTitle} display="flex" justifyContent="space-between" width="100%">
        <Typography variant="h5" style={{ fontWeight: 'bold', lineHeight: '36px' }} color="secondary">
          Fatura
        </Typography>

        <IconButton size="small" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent style={{ paddingBottom: 24 }}>
        {selectedStatement && (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.basic} style={{ width: '25%', fontWeight: 'bold' }}>
                  Data
                </TableCell>
                <TableCell className={classes.basic} style={{ width: '25%', fontWeight: 'bold' }}>
                  Hora
                </TableCell>
                <TableCell className={classes.basic} style={{ width: '25%', fontWeight: 'bold', textAlign: 'left' }}>
                  Sala
                </TableCell>
                <TableCell className={classes.basic} style={{ width: '25%', fontWeight: 'bold', textAlign: 'left' }}>
                  Tipo de Reserva
                </TableCell>
                <TableCell className={classes.basic} style={{ width: '25%', fontWeight: 'bold' }}>
                  Preço Original
                </TableCell>
                <TableCell className={classes.basic} style={{ width: '25%', fontWeight: 'bold' }}>
                  Desconto
                </TableCell>
                <TableCell className={classes.basic} style={{ width: '25%', fontWeight: 'bold' }}>
                  Preço Final
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {selectedStatement.reservations.map((reservation, index) => (
                <TableRow key={index} hover>
                  <TableCell className={classes.basic} style={{ width: '25%', fontWeight: 'bold' }}>
                    {stringToPrettyDate(reservation.date)}
                  </TableCell>
                  <TableCell className={classes.basic} style={{ width: '25%', fontWeight: 'bold' }}>
                    {reservation.time}
                  </TableCell>
                  <TableCell className={classes.basic} style={{ width: '25%', textAlign: 'left' }}>
                    {reservation.room.name}
                  </TableCell>
                  <TableCell className={classes.basic} style={{ width: '25%', textAlign: 'left' }}>
                    {getReservationType(reservation)}
                  </TableCell>
                  <TableCell className={classes.basic} style={{ width: '25%', textAlign: 'right' }}>
                    {formatCurrency(reservation.price)}
                  </TableCell>
                  <TableCell className={classes.basic} style={{ width: '25%', textAlign: 'right' }}>
                    {formatCurrency(reservation.discount)}
                  </TableCell>
                  <TableCell className={classes.basic} style={{ width: '25%', textAlign: 'right' }}>
                    {formatCurrency(reservation.final_price)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DialogContent>
    </Dialog>
  );
}
