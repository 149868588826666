import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '~/assets/logo.svg';
import MaskedInput from 'react-text-mask';
import { Box, Card, TextField, Typography, Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import { signUpRequest, clearError } from '~/store/modules/user/actions';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

const useStyles = makeStyles(theme => ({
  card: {
    padding: 16,
    borderRadius: 12,
    width: '50%',
    maxWidth: 700,
    minWidth: 315,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textBox: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  linkText: {
    textDecoration: 'underline',
    marginLeft: 4,
    fontWeight: 'bold',
  },
  button: {
    width: '100%',
    maxWidth: 300,
  },
  buttonLoading: {
    width: '30%',
    minWidth: 60,
  },
  dialogTitle: {
    padding: '24px 24px 16px 24px',
  },
}));

function PhoneMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}

function CPFMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}

export default function SignUp() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, registerError } = useSelector(state => state.user);
  const fieldErrors = useSelector(state => state.user.fieldErrors) || {};

  useEffect(() => {
    dispatch(clearError());
    return () => {
      dispatch(clearError());
    };
  }, [dispatch]);

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [cpf, setCPF] = useState('');
  const [rg, setRG] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [profession, setProfession] = useState('');
  const [licenseNumber, setLicenseNumber] = useState('');
  const [useTerms, setUseTerms] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleSignUp = async e => {
    e.preventDefault();
    dispatch(signUpRequest(username, email, password, confirmPassword, cpf, rg, phoneNumber, address, profession, licenseNumber, useTerms));
  };

  return (
    <>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="md" fullWidth>
        <Typography variant="h4" className={classes.dialogTitle}>
          <b>Termos de Uso</b>
        </Typography>

        <DialogContent>
          <Typography align="justify" gutterBottom>
            VIVER INTEGRAL – COWORKING, SAÚDE E AUTOCONHECIMENTO
          </Typography>
          <Typography align="justify" gutterBottom>
            DAS REGRAS GERAIS
          </Typography>
          <Typography align="justify">
            1. A Viver Integral dispõe dos espaços compartilhados mencionados no ANEXO I do presente documento, além das áreas comuns, quais
            sejam: recepção, terraço, banheiros e copa.
          </Typography>
          <Typography align="justify">
            2. O horário de funcionamento do Coworking é das 7h as 22h de segunda-feira a sexta-feira e das 7h as 18h aos sábados.
          </Typography>
          <Typography align="justify">
            3. A Viver Integral possui espaço específico para lanche (copa), assim, lanches deverão ser efetuados preferencialmente no local
            adequado e não nas salas de atendimento ou treinamento.
          </Typography>
          <Typography align="justify">
            4. A Viver Integral possui responsabilidade ambiental e contratual pelo descarte adequado dos lixos, cabendo ao usuário o
            descarte correto de cada tipo de lixo, assim compreendido: orgânico, seletivo, infectante e perfurocortante.
          </Typography>
          <Typography align="justify">
            5. As salas de atendimento e treinamentos possuem os itens descritos no ANEXO I do presente documento, cabendo ao usuário seus
            materiais de uso exclusivo, como por exemplo: lenços de papel, produtos específicos da sua área de atuação e/ou equipamentos
            (notebook, etc).
          </Typography>
          <Typography align="justify">
            6. O usuário poderá, a seu critério, deixar nos armários constantes nas salas de atendimento ou treinamento seus materiais
            exclusivos, contudo, a clínica contratada não se responsabiliza pela guarda, manutenção ou qualquer dano causado, por se tratar
            um local de acesso e uso compartilhado.
          </Typography>
          <Typography align="justify">
            7. Se o usuário observar qualquer dano ao patrimônio da Viver Integral, tanto nas áreas de uso comum, como nos espaços
            contratados (salas de treinamento ou atendimento) deverá comunicar imediatamente a direção.
          </Typography>
          <Typography align="justify">8. Não é permitido modificar os móveis ou objetos decorativos de lugar.</Typography>
          <Typography align="justify">
            9. É extremamente proibido afixar cartazes ou similares nas salas de atendimento ou treinamento.
          </Typography>
          <Typography align="justify">
            10. Não é permitido levar copos descartáveis para as salas de treinamentos. Se necessitar levar líquidos para a sala de
            treinamento use garrafas e/ou copos com tampa.
          </Typography>
          <Typography align="justify">
            11. Use os espaços comuns para intervalos e coffee-breaks. O terraço é considerado área de uso comum e poderá ser utilizado pelo
            usuário, para pausas, intervalos, sendo vedada utilização diversa da essência da Viver Integral, salvo por autorização expressa
            da direção.
          </Typography>
          <Typography align="justify">
            12. O pagamento pela contratação das horas ou turnos utilizados será devido até o 5º dia útil do mês subsequentes aos
            atendimentos, tendo como data base o último dia útil de atendimento.
          </Typography>
          <Typography align="justify">
            13. Em caso de não pagamento, além do cancelamento do acesso as dependências da clínica, é devida multa de 10%, além de juros
            legais e correção monetária. DO AGENDAMENTO DAS SALAS DE ATENDIMENTO E TREINAMENTO.
          </Typography>
          <Typography align="justify">
            14. O agendamento das salas é feito pelo site e/ou aplicativo, por meio de usuário e senha, sendo que as informações de sala e
            paciente aparecem apenas para o usuário logado, constando apenas a informação de sala indisponível ao outro usuário, quando o
            horário já possui algum agendamento, prezando dessa forma pelo sigilo.
          </Typography>
          <Typography align="justify">
            15. Os agendamentos só podem ser cancelados ou remarcados no prazo de 24 horas antes do horário agendado, caso contrário serão
            cobrados na íntegra na modalidade contratada (hora ou turno avulso).
          </Typography>
          <Typography align="justify">
            16. Para retirada da chave de acesso ao espaço de Coworking, o usuário deverá fazer contato junto à direção da clínica e
            retirar/devolver no local indicado. Lembre-se sempre de passar a chave na porta interna (de vidro) quando sair, a segurança é um
            dever de todos! DA POLÍTICA DE SAÍDA DO COWORKING
          </Typography>
          <Typography align="justify">
            17. O CHEKLIST de SAÍDA que compreende: deixar a sala como estava como encontrou; desligar o ar-condicionado e as luzes e
            verificar a limpeza é obrigatório e de responsabilidade exclusiva do usuário, passível de cobrança sobre os itens em desacordo.
          </Typography>
          <Typography align="justify">
            18. É de inteira responsabilidade do usuário os danos causados aos itens constantes no ANEXO I, ou qualquer outro dano material
            decorrente do mau uso nas dependências nas áreas de uso comum do Coworking.
          </Typography>
          <Typography align="justify" gutterBottom>
            19. Se você for o último a utilizar o Coworking, lembre-se de desligar as luzes, TV etc. O código para saber se há alguém
            atendendo no andar de cima é a luz da mandala verde no alto da escada acesa.
          </Typography>
          <Typography align="justify" gutterBottom>
            Data de criação: Fev/2022 Atualizado em: Fev/2022
          </Typography>
          <Typography align="justify" gutterBottom>
            ANEXO I: SALA DESCRIÇÃO
          </Typography>

          <Typography align="justify">EQUILÍBRIO</Typography>
          <Typography align="justify" gutterBottom>
            Sala com 17m2ª, possui pia com bancada, 1 maca, 1 mesa de atendimento com 2 poltronas na cor cinza, 1 cômoda, 4 prateleiras, 1
            ar condicionado e controle, 1 interfone, 2 lixeiras em alumínio, 1 persiana, 3 quadros decorativos, 1 poltrona cinza com
            rodinhas, 7 vasos sendo 5 com plantas artificiais, 1 face de metal preto, 2 pássaros brancos em porcelana, 1 coral, 2 caixas
            porta objetos em madeira e revestimento acrílico, um relógio de bancada, 1 dispense para toalha de papel, um espelho de parede e
            1 pufe com base de metal e revestimento cinza, 7 luminárias de teto e 1 na prateleira da bancada, aquecedor de água elétrico
            marca Lorenzetti.
          </Typography>
          <Typography align="justify">HARMONIA</Typography>
          <Typography align="justify" gutterBottom>
            Sala com 13 m2ª, possui 1 maca com 1 manta cinza e 1 travesseiro, 2 poltronas estofadas com 1 almofada cada, 1 tapete, 1
            ar-condicionado e controle, 1 mesa de aproximação, 1 cômoda, 1 luminária, 1 pufe estofado na cor cinza, 1 cadeira com rodinhas
            na cor cinza, 1 escada para maca em madeira, 3 vasos pequenos com plantas artificiais e um vaso médio com orquídeas artificiais,
            1 lixeira, 1 prateleira decorativa com 2 passarinhos de madeira e 1 imagem de mulher em cerâmica, 1 persiana, uma luminária
            coluna de piso. Parede revestida com papel de parede inteiro e sem manchas, uma luminária de teto.
          </Typography>
          <Typography align="justify">MANIFESTAÇÃO</Typography>
          <Typography align="justify" gutterBottom>
            Sala com 12 m2ª, possui 2 poltronas na cor azul, 1 mesa de madeira com 2 cadeiras na cor branca, 1 luminária de mesa, 1 tapete,
            1 luminária coluna de piso, 1 mesa de aproximação de metal e vidro,1 lixeira, 1 pufe, 1 ar condicionado e controle, 2 quadros
            decorativos, 2 persianas, 1 estante embutida, 1 porta retrato com imagem de meditante, 1 base de pedra semipreciosa, 3 cristais
            de quartzo, 1 um buda em resina marrom, 1 vaso grande de metal com orquídeas brancas artificiais, 1 luminária de teto.
          </Typography>
          <Typography align="justify">EXPANSÃO</Typography>
          <Typography align="justify" gutterBottom>
            Sala com 36 m2ª, possui 1 TV com cabo HDMI, 1 quadro branco, armários embutidos, 1 balcão de madeira com 1 aparelho de som, 1
            rack de madeira com rodinhas, 5 persianas, 2 ar-condicionado e 1 controle, 8 quadros decorativos, cadeiras em plástico na cor
            branca, 2 luminárias de teto, 4 luminárias de led.
          </Typography>
          <Typography align="justify">CONEXÃO</Typography>
          <Typography align="justify" gutterBottom>
            Sala com 27 m2ª, possui 3 cômodas sendo uma com 1 TV com cabo HDMI, 3 poltronas estofadas na cor marrom, 1 ar-condicionado e
            controle, 5 persianas, 2 mesas de apoio em madeira, 12 almofadas quadradas grandes, 12 almofadas pequenas, uma luminária de
            balcão, 1 porta vela em forma de flor de lotus, um relógio de balcão, 3 mantas, 2 luminárias de teto.
          </Typography>
        </DialogContent>
      </Dialog>

      <Card className={classes.card} elevation={5}>
        <img src={logo} alt="Viver Integral Coworking" />
        <Box mt={2} width="90%">
          <Typography align="center">
            Olá, que bom que você está interessado em se tornar um profissional do nosso espaço! Para dar sequência ao seu cadastro,
            precisamos que preencha os campos abaixo:
          </Typography>
        </Box>

        <form onSubmit={handleSignUp} noValidate>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <TextField
                autoComplete="off"
                name="username"
                value={username}
                onChange={e => setUsername(e.target.value)}
                variant="outlined"
                placeholder="Nome Completo"
                label="Nome Completo"
                fullWidth
                error={'username' in fieldErrors}
                helperText={'username' in fieldErrors ? fieldErrors.username : ''}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                name="email"
                autoComplete="off"
                value={email}
                onChange={e => setEmail(e.target.value)}
                variant="outlined"
                placeholder="exemplo@gmail.com"
                label="Email"
                type="email"
                fullWidth
                error={'email' in fieldErrors}
                helperText={'email' in fieldErrors ? fieldErrors.email : ''}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                name="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                variant="outlined"
                placeholder="Senha"
                label="Senha"
                type="password"
                fullWidth
                error={'password' in fieldErrors}
                helperText={'password' in fieldErrors ? fieldErrors.password : ''}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                name="confirm_password"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                variant="outlined"
                placeholder="Confirme sua senha"
                label="Confirme sua senha"
                type="password"
                style={{ marginBottom: 0 }}
                fullWidth
                error={'confirmPassword' in fieldErrors}
                helperText={'confirmPassword' in fieldErrors ? fieldErrors.confirmPassword : ''}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                autoComplete="off"
                name="cpf"
                value={cpf}
                onChange={e => setCPF(e.target.value)}
                variant="outlined"
                placeholder="CPF"
                label="CPF"
                fullWidth
                error={'cpf' in fieldErrors}
                helperText={'cpf' in fieldErrors ? fieldErrors.cpf : ''}
                InputProps={{
                  inputComponent: CPFMask,
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                autoComplete="off"
                name="rg"
                value={rg}
                onChange={e => setRG(e.target.value)}
                variant="outlined"
                placeholder="RG"
                label="RG"
                fullWidth
                error={'rg' in fieldErrors}
                helperText={'rg' in fieldErrors ? fieldErrors.rg : ''}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                autoComplete="off"
                name="phone_number"
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
                variant="outlined"
                placeholder="Telefone"
                label="Telefone"
                fullWidth
                error={'phoneNumber' in fieldErrors}
                helperText={'phoneNumber' in fieldErrors ? fieldErrors.phoneNumber : ''}
                InputProps={{
                  inputComponent: PhoneMask,
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                autoComplete="off"
                name="address"
                value={address}
                onChange={e => setAddress(e.target.value)}
                variant="outlined"
                placeholder="Endereço"
                label="Endereço"
                fullWidth
                error={'address' in fieldErrors}
                helperText={'address' in fieldErrors ? fieldErrors.address : ''}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                autoComplete="off"
                name="profession"
                value={profession}
                onChange={e => setProfession(e.target.value)}
                variant="outlined"
                placeholder="Profissão"
                label="Profissão"
                fullWidth
                error={'profession' in fieldErrors}
                helperText={'profession' in fieldErrors ? fieldErrors.profession : ''}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                autoComplete="off"
                name="license_number"
                value={licenseNumber}
                onChange={e => setLicenseNumber(e.target.value)}
                variant="outlined"
                placeholder="Registro de Profissão (se aplicável)"
                label="Registro de Profissão"
                fullWidth
                error={'licenseNumber' in fieldErrors}
              />
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Checkbox checked={useTerms} onChange={e => setUseTerms(e.target.checked)} color="secondary" />
                <Typography variant="h6">Li e concordo com os</Typography>
                <Typography
                  variant="h6"
                  color="secondary"
                  onClick={() => setDialogOpen(true)}
                  className={classes.linkText}
                  style={{ cursor: 'pointer' }}
                >
                  termos de uso
                </Typography>
                {'useTerms' in fieldErrors && (
                  <Typography variant="subtitle2" color="error" style={{ marginLeft: '16px' }}>
                    {fieldErrors.useTerms}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>

          <Box minHeight={16} mb={2}>
            {registerError && (
              <Typography variant="subtitle2" color="error" style={{ lineHeight: '16px' }}>
                {registerError}
              </Typography>
            )}
          </Box>

          <Box display="flex" justifyContent="center" width="100%">
            <Button
              type="submit"
              className={clsx(classes.button, {
                [classes.buttonLoading]: loading,
              })}
            >
              {loading ? <CircularProgress color="inherit" size={30} /> : 'Registrar'}
            </Button>
          </Box>

          <Box display="flex" justifyContent="center" mt={2} className={classes.textBox}>
            <Typography variant="h5">Ja possuí um usuário?</Typography>

            <Link to="/">
              <Typography variant="h5" color="secondary" className={classes.linkText}>
                Acesse aqui!
              </Typography>
            </Link>
          </Box>
        </form>
      </Card>
    </>
  );
}
