import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, TextField, Typography, Button } from '@material-ui/core';

import { toast } from 'react-toastify';
import { changePasswordRequest } from '~/store/modules/user/actions';

export default function Profile() {
  const dispatch = useDispatch();

  const { username, email, access_password: accessPassword } = useSelector(state => state.auth.user);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleChangePassword = () => {
    if (newPassword === confirmPassword) {
      dispatch(changePasswordRequest(currentPassword, newPassword));
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } else {
      toast.error('As senhas não são iguais');
    }
  };

  return (
    <Grid container spacing={2} style={{ padding: 24 }}>
      <Grid item xs={12}>
        <Typography variant="h4" color="secondary">
          Informações da conta
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          disabled
          name="username"
          autoComplete="off"
          value={username}
          onChange={() => {}}
          variant="outlined"
          label="Nome Completo"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          disabled
          name="email"
          autoComplete="off"
          value={email}
          onChange={() => {}}
          variant="outlined"
          placeholder="exemplo@gmail.com"
          label="Email"
          type="email"
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <TextField
          disabled
          name="access_password"
          autoComplete="off"
          value={accessPassword}
          onChange={() => {}}
          variant="outlined"
          label="Senha da Porta"
          fullWidth
        />
      </Grid>

      <Grid item xs={12} style={{ marginTop: 24 }}>
        <Typography variant="h4" color="secondary">
          Informações de segurança
        </Typography>
      </Grid>

      <Grid item xs={12} sm={3}>
        <TextField
          name="password"
          value={currentPassword}
          onChange={e => setCurrentPassword(e.target.value)}
          variant="outlined"
          placeholder="Senha atual"
          label="Senha atual"
          type="password"
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <TextField
          name="password"
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
          variant="outlined"
          placeholder="Senha nova"
          label="Senha nova"
          type="password"
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <TextField
          name="confirm_password"
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
          variant="outlined"
          placeholder="Confirme sua nova senha"
          label="Confirme sua senha"
          type="password"
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={3} container alignItems="center" justify="center">
        <Button fullWidth variant="contained" style={{ maxWidth: 200 }} onClick={handleChangePassword}>
          Alterar senha
        </Button>
      </Grid>
    </Grid>
  );
}
