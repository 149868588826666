import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '~/services/api';

import {
  clearSelectedContract,
  listContractsSuccess,
  listContractsFailure,
  fetchLockedTimesSuccess,
  fetchLockedTimesFailure,
  checkContractSuccess,
  checkContractFailure,
  createContractSuccess,
  createContractFailure,
  deleteContractSuccess,
  deleteContractFailure,
} from './actions';

export function* fetchLockedTimes({ payload }) {
  try {
    const response = yield call(api.post, 'contracts/locked_times', payload);

    const { locked_times: lockedTimes } = response.data;
    yield put(fetchLockedTimesSuccess(lockedTimes));
  } catch (err) {
    yield put(fetchLockedTimesFailure());

    const message = err?.response?.data?.message;
    toast.error(message || `Falha ao buscar conflitos de horários`);
  }
}

export function* checkContract({ payload }) {
  const { contract } = payload;
  try {
    const response = yield call(api.post, 'contracts/check', contract);

    const { reservations } = response.data;
    yield put(checkContractSuccess(reservations));
  } catch (err) {
    yield put(checkContractFailure());

    const message = err?.response?.data?.message;
    toast.error(message || `Falha ao checar o contrato`);
  }
}

export function* createContract({ payload }) {
  try {
    const response = yield call(api.post, 'contracts', payload);

    const { contract } = response.data;
    yield put(createContractSuccess(contract));
    yield put(clearSelectedContract());
  } catch (err) {
    yield put(createContractFailure());

    const message = err?.response?.data?.message;
    toast.error(message || `Falha ao criar o contrato`);
  }
}

export function* deleteContract({ payload }) {
  const { contractID, cancelationDate } = payload;
  try {
    const response = yield call(api.delete, `contracts/${contractID}_${cancelationDate}`);

    const { contract } = response.data;

    yield put(deleteContractSuccess(contract));
    yield put(clearSelectedContract());
  } catch (err) {
    yield put(deleteContractFailure());
    toast.error(`Falha ao deletar o contrato`);
  }
}

export function* listContracts() {
  try {
    const response = yield call(api.get, 'contracts');

    const { contracts } = response.data;

    yield put(listContractsSuccess(contracts));
  } catch (err) {
    yield put(listContractsFailure());
    toast.error('Falha ao buscar os contratos');
  }
}

export default all([
  takeLatest('@contract/FETCH_LOCKED_TIMES_REQUEST', fetchLockedTimes),
  takeLatest('@contract/CHECK_CONTRACT_REQUEST', checkContract),
  takeLatest('@contract/CREATE_CONTRACT_REQUEST', createContract),
  takeLatest('@contract/DELETE_CONTRACT_REQUEST', deleteContract),
  takeLatest('@contract/LIST_CONTRACTS_REQUEST', listContracts),
]);
