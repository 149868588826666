import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import clsx from 'clsx';
import { rgba } from 'polished';

import { isMobile } from 'react-device-detect';

import { isPast, getShiftList, getShiftLabel, getShiftHours } from '~/services/utils';

import ShiftDialog from './ShiftDialog';

const useStyles = makeStyles(() => ({
  basic: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 100,
    fontSize: 12,
    borderRadius: 10,
    border: '3px solid #F9F9F9',
    textAlign: 'center',
  },
  available: {
    cursor: 'pointer',
    background: rgba(0, 155, 159, 0.2),
    '&:hover': {
      background: rgba(0, 155, 159, 0.3),
    },
  },
  reservationConflict: {
    background: rgba(0, 0, 0, 0.03),
    color: rgba(0, 0, 0, 0.26),
  },
  reserved: {
    cursor: 'pointer',
    background: rgba(10, 35, 130, 0.15),
    '&:hover': {
      background: rgba(10, 35, 130, 0.25),
    },
  },
  locked: {
    background: rgba(0, 0, 0, 0.03),
    color: rgba(0, 0, 0, 0.26),
  },
  hoveredAvailable: {
    fontWeight: 'bolder',
    background: rgba(0, 155, 159, 0.3),
  },

  hoveredReserved: {
    fontWeight: 'bolder',
    background: rgba(10, 35, 130, 0.25),
  },
}));

export default function Overview({ calendarCurrentSlice, userReservedMap, selectedRoom }) {
  const classes = useStyles();

  const { lockedReservationsMap } = useSelector(state => state.reservation);

  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedShift, setSelectedShift] = useState(null);

  const [hoveredTime, setHoveredTime] = useState(null);
  const [hoveredDate, setHoveredDate] = useState(null);
  const [hoveredStatus, setHoveredStatus] = useState(null);

  const handleMouseEnter = (time, date, status) => {
    setHoveredTime(time);
    setHoveredDate(date);
    setHoveredStatus(status);
  };

  const handleMouseLeave = () => {
    setHoveredTime(null);
    setHoveredDate(null);
    setHoveredStatus(null);
  };

  return (
    <>
      <ShiftDialog
        selectedShift={selectedShift}
        setSelectedShift={setSelectedShift}
        selectedAction={selectedAction}
        setSelectedAction={setSelectedAction}
      />

      <Table size="small" style={{ height: 'calc(100vh - 120px)' }}>
        <Hidden xsDown>
          <TableHead>
            <TableRow>
              <TableCell className={classes.basic} style={{ width: 40, textAlign: 'left' }} />
              {calendarCurrentSlice.map(([rawDate, formattedDate]) => (
                <TableCell
                  className={clsx(classes.basic, {
                    [classes.hoveredAvailable]: rawDate === hoveredDate && hoveredStatus === 'AVAILABLE',
                    [classes.hoveredReserved]: rawDate === hoveredDate && hoveredStatus === 'RESERVED',
                  })}
                  style={{ fontWeight: 'bold' }}
                  key={rawDate}
                  align="center"
                >
                  {formattedDate}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        </Hidden>

        <TableBody onMouseLeave={() => handleMouseLeave()}>
          {getShiftList().map(period => (
            <TableRow key={period}>
              <Hidden xsDown>
                <TableCell
                  className={clsx(classes.basic, {
                    [classes.hoveredAvailable]: period === hoveredTime && hoveredStatus === 'AVAILABLE',
                    [classes.hoveredReserved]: period === hoveredTime && hoveredStatus === 'RESERVED',
                  })}
                  style={{ fontWeight: 'bold', textAlign: 'center' }}
                >
                  {getShiftLabel(period)}
                </TableCell>
              </Hidden>

              {calendarCurrentSlice.map(([rawDate]) => {
                let status = 'AVAILABLE';
                const shiftKey = `${rawDate} ${period}`;

                const shiftHours = getShiftHours(period);

                const hasUserConflict = shiftHours.find(time => `${rawDate} ${time} ${selectedRoom.id}` in lockedReservationsMap);
                if (hasUserConflict || isPast(rawDate, shiftHours[0])) status = 'LOCKED';

                const reservations = shiftHours.map(time => userReservedMap[`${rawDate} ${time}`]).filter(r => !!r);
                let existingShiftReservation;

                if (reservations.length === shiftHours.length) {
                  const reserve = reservations[0];
                  if (reserve.shift_id) {
                    existingShiftReservation = {
                      id: reserve.shift_id,
                      contract_id: reserve.contract_id,
                      date: rawDate,
                      period: period,
                      room: reserve.room,
                      created_at: reserve.created_at,
                      notes: reservations.map(r => r.note),
                    };

                    status = 'RESERVED';
                  } else status = 'RESERVATION_CONFLICT';
                } else if (reservations.length > 0) status = 'RESERVATION_CONFLICT';

                return (
                  <TableCell
                    onMouseEnter={() => handleMouseEnter(period, rawDate, status)}
                    className={clsx(classes.basic, {
                      [classes.available]: status === 'AVAILABLE',
                      [classes.reserved]: status === 'RESERVED',
                      [classes.reservationConflict]: status === 'RESERVATION_CONFLICT',
                      [classes.locked]: status === 'LOCKED',
                    })}
                    key={shiftKey}
                    onClick={() => {
                      if (status === 'AVAILABLE') {
                        setSelectedShift({
                          date: rawDate,
                          period,
                          room: selectedRoom,
                          notes: [null, null, null, null],
                        });

                        setSelectedAction('create');
                      } else if (status === 'RESERVED') {
                        setSelectedShift(existingShiftReservation);
                        setSelectedAction('update');
                      }
                    }}
                  >
                    {isMobile && period}
                    {status === 'AVAILABLE' && !isMobile && 'Disponível'}
                    {status === 'RESERVATION_CONFLICT' && !isMobile && 'Conflito com reserva horária'}
                    {status === 'RESERVED' && !isMobile && (existingShiftReservation?.room?.name || 'Reservado')}
                    {status === 'LOCKED' && !isMobile && 'Indisponível'}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
