import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import clsx from 'clsx';
import { rgba } from 'polished';
import _ from 'lodash';

import { Box, Typography, Button, IconButton, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import VerifyIcon from '@material-ui/icons/PlaylistAddCheck';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import {
  clearSelectedContract,
  checkContractRequest,
  createContractRequest,
  deleteContractRequest,
  updateSelectedContractInfo,
  clearCheckedReservations,
  fetchLockedTimesRequest,
  clearLockedTimes,
} from '~/store/modules/contract/actions';

import { getHoursOfDay, getDaysOfWeekNumbers, getDaysOfWeek, stringToPrettyDate } from '~/services/utils';
import { userListRequest } from '~/store/modules/admin/actions';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    padding: '16px 16px 8px 16px',
  },

  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px 16px 16px',
  },

  deleteButton: {
    width: 120,
    background: '#FFF',
    color: theme.palette.error.main,
    boxShadow: 'none',
    '&:hover': {
      color: theme.palette.error.contrastText,
      background: theme.palette.error.dark,
    },
  },

  basic: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 12,
    border: '3px solid #F9F9F9',
    textAlign: 'center',
    lineHeight: '17px',
  },

  lockedTime: {
    background: rgba(0, 0, 0, 0.03),
    color: rgba(0, 0, 0, 0.26),
  },

  hoveredAvailable: {
    background: rgba(0, 155, 159, 0.3),
  },

  selected: {
    background: rgba(10, 35, 130, 0.25),
  },
}));

export default function ContractManagementDialog() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { users } = useSelector(state => state.admin);
  const { rooms } = useSelector(state => state.room);

  const { selectedContract, checkedReservations, lockedTimes } = useSelector(state => state.contract);

  const [step, setStep] = useState('config');
  const [cancelationDate, setCancelationDate] = useState('');

  const [hoveredTime, setHoveredTime] = useState(null);
  const [hoveredDay, setHoveredDay] = useState(null);

  const [lockedTimesQuery, setLockedTimesQuery] = useState(null);

  const handleMouseEnter = (time, day) => {
    if (!selectedContract.id && lockedTimes) {
      setHoveredTime(time);
      setHoveredDay(day);
    }
  };

  const handleMouseLeave = () => {
    setHoveredTime(null);
    setHoveredDay(null);
  };

  const getMinDate = () => {
    let today = new Date();
    return today.toISOString().split('T')[0];
  };

  const handleClose = () => {
    setStep('config');
    setCancelationDate('');
    dispatch(clearLockedTimes());
    dispatch(clearSelectedContract());
    dispatch(clearCheckedReservations());
  };

  const handleCreate = () => {
    const reservations = _.cloneDeep(checkedReservations)
      .filter(reservation => !reservation.availability)
      .map(reservation => {
        delete reservation.availability;
        return reservation;
      });

    dispatch(createContractRequest(selectedContract, reservations));
    handleClose();
  };

  const handleDelete = contractID => {
    if (cancelationDate) {
      dispatch(deleteContractRequest(contractID, cancelationDate));
      setCancelationDate('');
    }
  };

  const handleChange = (field, value) => {
    if (field === 'start_date') dispatch(clearLockedTimes());
    dispatch(updateSelectedContractInfo(field, value));
  };

  const handleBack = () => {
    setStep('config');
    dispatch(clearCheckedReservations());
  };

  const handleCheck = () => {
    dispatch(checkContractRequest(selectedContract));
    setStep('check');
  };

  const handleSelect = (time, dayLabel) => {
    if (!selectedContract.id && !isLockedCell(time, dayLabel)) {
      const updatedConfig = _.cloneDeep(selectedContract.reservations_config);
      const dayNumber = getDaysOfWeekNumbers()[dayLabel];
      if (updatedConfig[dayNumber].includes(time)) updatedConfig[dayNumber] = updatedConfig[dayNumber].filter(h => h !== time);
      else updatedConfig[dayNumber].push(time);
      dispatch(updateSelectedContractInfo('reservations_config', updatedConfig));
    }
  };

  const isSelected = (time, dayLabel) => {
    const dayNumber = getDaysOfWeekNumbers()[dayLabel];
    return selectedContract.reservations_config[dayNumber].includes(time);
  };

  const isLockedCell = (time, dayLabel) => {
    if (!lockedTimes) return true;

    const dayNumber = getDaysOfWeekNumbers()[dayLabel];

    if (selectedContract.id) return !selectedContract.reservations_config[dayNumber].includes(time);

    return lockedTimes[dayNumber].includes(time);
  };

  const isInvalidContract = () => {
    if (!selectedContract?.user_id) return true;
    if (!selectedContract?.room_id) return true;
    if (!selectedContract?.contract_type) return true;
    if (!selectedContract?.price) return true;
    if (!selectedContract?.start_date) return true;
    if (!selectedContract?.end_date) return true;

    const configs = selectedContract?.reservations_config;

    if (configs) {
      return !Object.values(configs).some(value => {
        return value.length > 0;
      });
    }

    return false;
  };

  useEffect(() => {
    dispatch(userListRequest());
  }, [dispatch]);

  useEffect(() => {
    const roomID = selectedContract?.room_id;
    const startDate = selectedContract?.start_date;
    const endDate = selectedContract?.end_date;

    const newLockedTimesQuery = [roomID, startDate, endDate].join('_');

    if (roomID && startDate && endDate && newLockedTimesQuery !== lockedTimesQuery) {
      setLockedTimesQuery(newLockedTimesQuery);
      dispatch(fetchLockedTimesRequest(roomID, startDate, endDate));
    }
  }, [dispatch, selectedContract, lockedTimesQuery]);

  return (
    <Dialog open={!!selectedContract} onClose={handleClose} maxWidth="md" fullWidth>
      <Box className={classes.dialogTitle} display="flex" justifyContent="space-between" width="100%">
        <Typography variant="h4">{step === 'config' ? <b>Contrato</b> : <b>Conflito de Reservas</b>}</Typography>
        <IconButton size="small" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      {step === 'config' && (
        <DialogContent>
          {selectedContract && (
            <Grid container spacing={1}>
              <Grid item xs={3} style={{ display: 'grid' }}>
                <Box style={{ gridRow: 1, justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="room">Selecione um usuário</InputLabel>
                    <Select
                      name="user_id"
                      value={selectedContract.user_id}
                      onChange={e => handleChange(e.target.name, e.target.value)}
                      label="Selecione um Usuário"
                      disabled={selectedContract.id}
                    >
                      {users.map(option => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.username}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="room">Selecione uma sala</InputLabel>
                    <Select
                      name="room_id"
                      value={selectedContract.room_id}
                      onChange={e => handleChange(e.target.name, e.target.value)}
                      label="Selecione uma Sala"
                      disabled={selectedContract.id}
                    >
                      {rooms.map(option => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="type">Selecione o tipo de contrato</InputLabel>
                    <Select
                      name="contract_type"
                      value={selectedContract.contract_type}
                      onChange={e => handleChange(e.target.name, e.target.value)}
                      label="Selecione o Tipo de Contrato"
                      disabled={selectedContract.id}
                    >
                      <MenuItem key={'HOURLY'} value={'HOURLY'}>
                        Cobrança Horária
                      </MenuItem>
                      <MenuItem key={'MONTHLY'} value={'MONTHLY'}>
                        Cobrança Mensal
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    autoComplete="off"
                    name="price"
                    value={selectedContract.price}
                    onChange={e => handleChange(e.target.name, e.target.value)}
                    variant="outlined"
                    placeholder="35.00"
                    label={selectedContract.contract_type === 'HOURLY' ? 'Preço hora' : 'Preço Mês'}
                    disabled={selectedContract.contract_type === '' || selectedContract.id}
                    fullWidth
                  />

                  <TextField
                    autoComplete="off"
                    name="start_date"
                    type="date"
                    value={selectedContract.start_date}
                    onChange={e => handleChange(e.target.name, e.target.value)}
                    variant="outlined"
                    label="Data de Início"
                    placeholder="Data de Início"
                    disabled={selectedContract.contract_type === '' || selectedContract.id}
                    InputLabelProps={{ shrink: true }}
                  />

                  <TextField
                    autoComplete="off"
                    name="end_date"
                    type="date"
                    value={selectedContract.end_date}
                    onChange={e => handleChange(e.target.name, e.target.value)}
                    variant="outlined"
                    label="Data de Término"
                    placeholder="Data de Término"
                    disabled={selectedContract.contract_type === '' || selectedContract.id}
                    InputLabelProps={{ shrink: true }}
                  />

                  <TextField
                    autoComplete="off"
                    name="cancelation_date"
                    type="date"
                    value={cancelationDate}
                    onChange={e => setCancelationDate(e.target.value)}
                    variant="outlined"
                    label="Data de Cancelamento"
                    placeholder="Data de Cancelamento"
                    disabled={!selectedContract.id}
                    InputProps={{ inputProps: { min: getMinDate() } }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      {getDaysOfWeek().map(dayLabel => (
                        <TableCell className={classes.basic} style={{ width: '12.5%', fontWeight: 'bold' }}>
                          {dayLabel}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody onMouseLeave={() => handleMouseLeave()}>
                    {getHoursOfDay().map(hour => (
                      <TableRow key={hour}>
                        {getDaysOfWeek().map(dayLabel => (
                          <TableCell
                            key={dayLabel}
                            onMouseEnter={() => handleMouseEnter(hour, dayLabel)}
                            onClick={() => handleSelect(hour, dayLabel)}
                            className={clsx(classes.basic, {
                              [classes.lockedTime]: isLockedCell(hour, dayLabel),
                              [classes.selected]: isSelected(hour, dayLabel),
                              [classes.hoveredAvailable]:
                                !isLockedCell(hour, dayLabel) &&
                                !isSelected(hour, dayLabel) &&
                                hour === hoveredTime &&
                                dayLabel === hoveredDay,
                            })}
                          >
                            {hour}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      )}

      {step === 'check' && (
        <DialogContent width="100%">
          {checkedReservations && (
            <Box display="flex" flexDirection="column">
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.basic} style={{ width: '25%', fontWeight: 'bold' }}>
                      Data
                    </TableCell>
                    <TableCell className={classes.basic} style={{ width: '25%', fontWeight: 'bold' }}>
                      Hora
                    </TableCell>
                    <TableCell className={classes.basic} style={{ width: '50%', fontWeight: 'bold' }}>
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {checkedReservations
                    .filter(reservation => !!reservation.availability)
                    .map((reservation, index) => (
                      <TableRow key={index} hover>
                        <TableCell className={classes.basic} style={{ width: '25%', fontWeight: 'bold' }}>
                          {stringToPrettyDate(reservation.date)}
                        </TableCell>
                        <TableCell className={classes.basic} style={{ width: '25%', fontWeight: 'bold' }}>
                          {reservation.time}
                        </TableCell>
                        <TableCell className={classes.basic} style={{ width: '50%' }}>
                          {reservation.availability}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          )}
        </DialogContent>
      )}

      {step === 'config' && (
        <DialogActions className={classes.dialogActions}>
          <Box flexGrow={1} display={'flex'} justifyContent={'flex-end'}>
            {selectedContract && selectedContract.id ? (
              <Button
                disabled={!cancelationDate}
                onClick={() => handleDelete(selectedContract.id)}
                className={classes.deleteButton}
                variant="contained"
                startIcon={<DeleteIcon />}
              >
                Cancelar
              </Button>
            ) : (
              <Button
                onClick={handleCheck}
                disabled={isInvalidContract()}
                variant="contained"
                color="primary"
                style={{ width: 120, marginRight: 16 }}
                startIcon={<VerifyIcon />}
              >
                Checar
              </Button>
            )}
          </Box>
        </DialogActions>
      )}

      {step === 'check' && (
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleBack} variant="contained" color="primary" style={{ width: 120 }}>
            Voltar
          </Button>

          <Button
            onClick={handleCreate}
            disabled={isInvalidContract()}
            variant="contained"
            color="primary"
            style={{ width: 120 }}
            startIcon={<CheckIcon />}
          >
            Criar
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
