import produce from 'immer';

const INITIAL_STATE = {
  users: [],
  reservations: [],
  reservationLoading: false,

  loading: false,
  selectedUser: null,
  userListError: null,
  roomListError: null,
  shouldClean: false,
  userReservations: [],
  roomReservations: [],
  userReservationListError: null,
  roomReservationListError: null,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@admin/CLEAR_SELECTED_USER': {
        draft.selectedUser = null;
        break;
      }

      case '@admin/SET_SELECTED_USER': {
        draft.selectedUser = action.payload.selectedUser;
        break;
      }

      case '@admin/UPDATE_SELECTED_USER_INFO':
        const { field, value } = action.payload;
        draft.selectedUser[field] = value;
        break;

      case '@admin/USER_LIST_REQUEST': {
        draft.loading = true;
        draft.userListError = null;
        break;
      }

      case '@admin/USER_LIST_SUCCESS': {
        draft.users = action.payload.users;
        draft.loading = false;
        draft.userListError = null;
        break;
      }

      case '@admin/USER_LIST_FAILURE': {
        draft.loading = false;
        draft.userListError = action.payload.userListError;
        break;
      }

      case '@admin/USER_UPDATE_BY_ADMIN_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@admin/USER_UPDATE_BY_ADMIN_SUCCESS': {
        const { user } = action.payload;
        draft.users = [user, ...state.users.filter(u => u.id !== user.id)];
        draft.selectedUser = null;
        draft.loading = false;
        break;
      }

      case '@admin/USER_UPDATE_BY_ADMIN_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@admin/CLEAR_RESERVATIONS': {
        draft.reservations = [];
        break;
      }

      case '@admin/RESERVATION_LIST_REQUEST': {
        draft.reservationLoading = true;
        break;
      }

      case '@admin/RESERVATION_LIST_SUCCESS': {
        draft.reservations = action.payload.reservations;
        draft.reservationLoading = false;
        break;
      }

      case '@admin/RESERVATION_LIST_FAILURE': {
        draft.reservationLoading = false;
        break;
      }

      case '@admin/CLEAR_ROOM_RESERVATIONS': {
        draft.roomReservations = [];
        break;
      }

      case '@admin/ROOM_RESERVATION_LIST_REQUEST': {
        draft.reservationLoading = true;
        draft.roomReservationListError = null;
        break;
      }

      case '@admin/ROOM_RESERVATION_LIST_SUCCESS': {
        draft.roomReservations = action.payload.roomReservations;
        draft.reservationLoading = false;
        draft.roomReservationListError = null;
        break;
      }

      case '@admin/ROOM_RESERVATION_LIST_FAILURE': {
        draft.reservationLoading = false;
        draft.roomReservationListError = action.payload.roomReservationListError;
        break;
      }

      default:
    }
  });
}
