import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '~/services/api';

import {
  listRoomsSuccess,
  listRoomsFailure,
  mergeRoomSuccess,
  mergeRoomFailure,
  clearSelectedRoom,
  deleteRoomSuccess,
  deleteRoomFailure,
} from './actions';

export function* listRooms() {
  try {
    const response = yield call(api.get, 'rooms');

    const { rooms } = response.data;

    yield put(listRoomsSuccess(rooms));
  } catch (err) {
    yield put(listRoomsFailure());
    toast.error('Falha ao buscar as salas');
  }
}

export function* mergeRoom({ payload }) {
  const { room, action } = payload;
  try {
    const method = action === 'create' ? api.post : api.put;
    const route = action === 'create' ? 'rooms' : `rooms/${room.id}`;

    const response = yield call(method, route, room);

    const mergedRoom = response.data.room;

    yield put(mergeRoomSuccess(mergedRoom));
    yield put(clearSelectedRoom());
  } catch (err) {
    yield put(mergeRoomFailure());
    toast.error(`Falha ao ${action === 'create' ? 'criar' : 'atualizar'} a sala`);
  }
}

export function* deleteRoom({ payload }) {
  const { roomId } = payload;
  try {
    const response = yield call(api.delete, `rooms/${roomId}`);

    const deletedRoom = response.data.room;

    yield put(deleteRoomSuccess(deletedRoom));
    yield put(clearSelectedRoom());
  } catch (err) {
    yield put(deleteRoomFailure());
    toast.error(`Falha ao deletar a room`);
  }
}

export default all([
  takeLatest('@room/LIST_ROOMS_REQUEST', listRooms),
  takeLatest('@room/MERGE_ROOM_REQUEST', mergeRoom),
  takeLatest('@room/DELETE_ROOM_REQUEST', deleteRoom),
]);
