import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { formatRawDate } from '~/services/utils';

import {
  createHourReservationSuccess,
  createHourReservationFailure,
  updateHourReservationFailure,
  updateHourReservationSuccess,
  clearSelectedReservation,
  listUserUpcomingSuccess,
  listUserUpcomingFailure,
  listLockedSuccess,
  listLockedFailure,
  listUserReservationsByMonthSuccess,
  listUserReservationsByMonthFailure,
  deleteHourReservationSuccess,
  deleteHourReservationFailure,
} from './actions';

export function* createHourReservation({ payload }) {
  const { newReservation } = payload;

  try {
    const response = yield call(api.post, 'reservations', newReservation);
    const { status, message, reservation } = response.data;

    if (status === 'ERROR') {
      yield put(createHourReservationFailure());
      toast.error(message);
    } else {
      yield put(createHourReservationSuccess(reservation));
      yield put(clearSelectedReservation());
    }
  } catch (err) {
    yield put(createHourReservationFailure());
    toast.error(`Falha ao criar a reserva`);
  }
}

export function* updateHourReservation({ payload }) {
  const { existingReservation } = payload;

  try {
    const response = yield call(api.put, `reservations/${existingReservation.id}`, existingReservation);
    const { status, message, reservation } = response.data;

    if (status === 'ERROR') {
      yield put(updateHourReservationFailure());
      toast.error(message);
    } else {
      yield put(updateHourReservationSuccess(reservation));
      yield put(clearSelectedReservation());
    }
  } catch (err) {
    yield put(updateHourReservationFailure());
    toast.error(`Falha ao atualizar a reserva`);
  }
}

export function* deleteHourReservation({ payload }) {
  const { reservationID } = payload;
  try {
    const response = yield call(api.delete, `reservations/${reservationID}`);

    const deletedReservation = response.data;

    yield put(deleteHourReservationSuccess(deletedReservation));
    yield put(clearSelectedReservation());
  } catch (err) {
    yield put(deleteHourReservationFailure());
    toast.error(`Falha ao deletar a reserva`);
  }
}

export function* listUserUpcomingReservations() {
  try {
    const response = yield call(api.get, 'reservations');

    const userUpcomingReservations = response.data;

    yield put(listUserUpcomingSuccess(userUpcomingReservations));
  } catch (err) {
    yield put(listUserUpcomingFailure());
    toast.error('Falha ao buscar as reservas');
  }
}

export function* listLockedReservations() {
  try {
    const response = yield call(api.get, 'reservations', { params: { locked: true } });

    const lockedReservations = response.data;

    const lockedReservationsMap = {};

    lockedReservations.forEach(({ date, time, room }) => {
      const key = `${date} ${time} ${room.id}`;
      lockedReservationsMap[key] = null;
    });

    yield put(listLockedSuccess(lockedReservationsMap));
  } catch (err) {
    yield put(listLockedFailure());
    toast.error('Falha ao buscar as reservas');
  }
}

export function* listUserReservationsByMonth() {
  try {
    const today = formatRawDate(new Date());
    const startDate = `${today.slice(0, -2)}00`;
    const endDate = `${today.slice(0, -2)}99`;

    const response = yield call(api.get, 'reservations', { params: { start_date: startDate, end_date: endDate } });

    const reservationsByMonth = response.data;

    yield put(listUserReservationsByMonthSuccess(reservationsByMonth));
  } catch (err) {
    yield put(listUserReservationsByMonthFailure());
    toast.error('Falha ao buscar as reservas');
  }
}

export default all([
  takeLatest('@reservation/CREATE_HOUR_RESERVATION_REQUEST', createHourReservation),
  takeLatest('@reservation/UPDATE_HOUR_RESERVATION_REQUEST', updateHourReservation),
  takeLatest('@reservation/DELETE_HOUR_RESERVATION_REQUEST', deleteHourReservation),
  takeLatest('@reservation/LIST_USER_UPCOMING_REQUEST', listUserUpcomingReservations),
  takeLatest('@reservation/LIST_USER_RESERVATION_BY_MONTH_REQUEST', listUserReservationsByMonth),
  takeLatest('@reservation/LIST_LOCKED_REQUEST', listLockedReservations),
]);
