import React from 'react';
import { useDispatch } from 'react-redux';

import { Box, Typography, Grid, Button, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';

import _ from 'lodash';

import { isMobile } from 'react-device-detect';

import {
  createShiftReservationRequest,
  updateShiftReservationRequest,
  deleteShiftReservationRequest,
} from '~/store/modules/shift_reservation/actions';
import { stringToPrettyDate, isCancelable, getShiftHours, getShiftLabel } from '~/services/utils';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    padding: '24px 24px 16px 24px',
  },

  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 24px 24px 24px',
  },

  deleteButton: {
    background: '#FFF',
    color: theme.palette.error.main,
    boxShadow: 'none',
    '&:hover': {
      color: theme.palette.error.contrastText,
      background: theme.palette.error.dark,
    },
  },
}));

export default function ShiftDialog({ selectedShift, setSelectedShift, selectedAction, setSelectedAction }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClose = () => {
    setSelectedShift(null);
    setSelectedAction(null);
  };

  const handleReserve = () => {
    if (selectedAction === 'create') {
      dispatch(createShiftReservationRequest(selectedShift));
    } else {
      dispatch(updateShiftReservationRequest(selectedShift));
    }

    handleClose();
  };

  const handleDelete = () => {
    dispatch(deleteShiftReservationRequest(selectedShift.id));

    handleClose();
  };

  const handleChangeNote = (note, index) => {
    const updatedSelectedShift = _.cloneDeep(selectedShift);
    updatedSelectedShift.notes[index] = note;
    setSelectedShift(updatedSelectedShift);
  };

  return (
    <Dialog open={!!selectedShift && !!selectedAction} onClose={handleClose} maxWidth="md">
      <Box className={classes.dialogTitle} display="flex" justifyContent="space-between" width="100%">
        <Typography variant="h4">
          <b>Confirme {isMobile ? '' : 'os dados de'} sua reserva</b>
        </Typography>
        <IconButton size="small" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        {selectedShift && (
          <Grid container spacing={1}>
            <Grid item container xs={12} alignItems="center">
              <Typography variant="h5" gutterBottom color="secondary">
                <b>{stringToPrettyDate(selectedShift.date)}</b> - <b>{getShiftLabel(selectedShift.period)}</b>
              </Typography>
            </Grid>
            <Grid item container xs={12} alignItems="center">
              <Typography variant="h5" gutterBottom color="secondary" style={{ marginRight: 4 }}>
                <b>Sala:</b>
              </Typography>
              <Typography variant="h5" gutterBottom>
                {selectedShift.room.name}
              </Typography>
            </Grid>
            <Grid item container xs={12} alignItems="center">
              <Typography variant="h5" gutterBottom color="secondary" style={{ marginRight: 4 }}>
                <b>Tipo de Reserva:</b>
              </Typography>
              <Typography variant="h5" gutterBottom>
                Turno
              </Typography>
            </Grid>
            <Grid item container xs={12} alignItems="center">
              <Typography variant="h5" gutterBottom color="secondary" style={{ marginRight: 4 }}>
                <b>Contrato:</b>
              </Typography>
              <Typography variant="h5" gutterBottom>
                {selectedShift.contract_id ? 'Sim' : 'Não'}
              </Typography>
            </Grid>

            {getShiftHours(selectedShift.period).map(function(hour, index) {
              return (
                <Grid item xs={12} key={index}>
                  <TextField
                    autoComplete="off"
                    name="note"
                    value={selectedShift.notes[index]}
                    onChange={e => handleChangeNote(e.target.value, index)}
                    variant="outlined"
                    placeholder="Nome do paciente ou anotação"
                    label={`Anotação para às ${hour}`}
                    fullWidth
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {selectedAction === 'update' ? (
          <Button
            disabled={!isCancelable(selectedShift)}
            onClick={handleDelete}
            className={classes.deleteButton}
            variant="contained"
            style={{ width: 180 }}
            startIcon={<DeleteIcon />}
          >
            Cancelar Turno
          </Button>
        ) : (
          <div />
        )}

        <Button onClick={handleReserve} variant="contained" color="primary" style={{ width: 180 }} startIcon={<CheckIcon />}>
          {selectedAction === 'create' ? 'Reservar Turno' : 'Atualizar Turno'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
