import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  dates: [],
  selectedReservation: null,
  reservationAction: null,
  userUpcomingReservations: [],
  reservationsByMonth: [],
  lockedReservationsMap: {},
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@auth/SIGN_OUT':
        draft.selectedReservation = null;
        draft.userUpcomingReservations = [];
        draft.reservationsByMonth = [];
        draft.lockedReservationsMap = {};
        break;

      case '@reservation/SET_DATES':
        draft.dates = action.payload.dates;
        break;

      case '@reservation/SET_SELECTED_RESERVATION':
        draft.selectedReservation = action.payload.selectedReservation;
        draft.reservationAction = action.payload.action;
        break;

      case '@reservation/CLEAR_SELECTED_RESERVATION':
        draft.selectedReservation = null;
        draft.reservationAction = null;
        break;

      case '@reservation/UPDATE_SELECTED_RESERVATION_INFO':
        const { field, value } = action.payload;
        draft.selectedReservation[field] = value;
        break;

      case '@reservation/CREATE_HOUR_RESERVATION_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@reservation/CREATE_HOUR_RESERVATION_SUCCESS': {
        const { reservation } = action.payload;

        draft.userUpcomingReservations = [reservation, ...state.userUpcomingReservations.filter(r => r.id !== reservation.id)];

        draft.loading = false;
        break;
      }

      case '@reservation/CREATE_HOUR_RESERVATION_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@reservation/UPDATE_HOUR_RESERVATION_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@reservation/UPDATE_HOUR_RESERVATION_SUCCESS': {
        const { reservation } = action.payload;

        draft.userUpcomingReservations = [reservation, ...state.userUpcomingReservations.filter(r => r.id !== reservation.id)];

        draft.loading = false;
        break;
      }

      case '@reservation/UPDATE_HOUR_RESERVATION_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@reservation/MERGE_RESERVATION_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@reservation/MERGE_RESERVATION_SUCCESS': {
        const { result } = action.payload;

        if (Array.isArray(result)) {
          const idSet = new Set(result.map(r => r.id));
          draft.userUpcomingReservations = [...result, ...state.userUpcomingReservations.filter(r => !idSet.has(r.id))];
        } else {
          draft.userUpcomingReservations = [result, ...state.userUpcomingReservations.filter(r => r.id !== result.id)];
        }

        draft.loading = false;
        break;
      }

      case '@reservation/MERGE_RESERVATION_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@reservation/DELETE_HOUR_RESERVATION_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@reservation/DELETE_HOUR_RESERVATION_SUCCESS': {
        const { deletedReservation } = action.payload;
        draft.userUpcomingReservations = [...state.userUpcomingReservations.filter(r => r.id !== deletedReservation.id)];
        draft.loading = false;
        break;
      }

      case '@reservation/DELETE_HOUR_RESERVATION_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@reservation/LIST_USER_UPCOMING_REQUEST': {
        draft.loading = false;
        break;
      }

      case '@reservation/LIST_USER_UPCOMING_SUCCESS': {
        draft.userUpcomingReservations = action.payload.userUpcomingReservations;
        draft.loading = false;
        break;
      }

      case '@reservation/LIST_USER_UPCOMING_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@reservation/LIST_LOCKED_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@reservation/LIST_LOCKED_SUCCESS': {
        draft.lockedReservationsMap = action.payload.lockedReservationsMap;
        draft.loading = false;
        break;
      }

      case '@reservation/LIST_LOCKED_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@reservation/LIST_USER_RESERVATION_BY_MONTH_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@reservation/LIST_USER_RESERVATION_BY_MONTH_SUCCESS': {
        draft.reservationsByMonth = action.payload.reservationsByMonth;
        draft.loading = false;
        break;
      }

      case '@reservation/LIST_USER_RESERVATION_BY_MONTH_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@shift_reservation/CREATE_SHIFT_RESERVATION_SUCCESS': {
        const { reservations } = action.payload;

        const newReservationsIdMap = new Set(reservations.map(r => r.id));

        draft.userUpcomingReservations = [...reservations, ...state.userUpcomingReservations.filter(r => !newReservationsIdMap.has(r.id))];

        break;
      }

      case '@shift_reservation/UPDATE_SHIFT_RESERVATION_SUCCESS': {
        const { reservations } = action.payload;

        const newReservationsIdMap = new Set(reservations.map(r => r.id));

        draft.userUpcomingReservations = [...reservations, ...state.userUpcomingReservations.filter(r => !newReservationsIdMap.has(r.id))];

        break;
      }

      case '@shift_reservation/DELETE_SHIFT_RESERVATION_SUCCESS': {
        const { deletedShift } = action.payload;

        draft.userUpcomingReservations = [...state.userUpcomingReservations.filter(r => r.shift_id !== deletedShift.id)];
        break;
      }

      default:
    }
  });
}
