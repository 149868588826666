import { combineReducers } from 'redux';

import auth from './auth/reducer';
import user from './user/reducer';
import env from './env/reducer';
import reservation from './reservation/reducer';
import shift_reservation from './shift_reservation/reducer';
import contract from './contract/reducer';
import statement from './statement/reducer';
import room from './room/reducer';
import admin from './admin/reducer';

export default combineReducers({
  auth,
  user,
  env,
  reservation,
  shift_reservation,
  contract,
  statement,
  room,
  admin,
});
