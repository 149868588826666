import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Button, Grid, TextField, Typography, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { userUpdateByAdminRequest, updateSelectedUserInfo, clearSelectedUser } from '~/store/modules/admin/actions';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import CloseIcon from '@material-ui/icons/Close';

import MaskedInput from 'react-text-mask';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import _ from 'lodash';

import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    padding: '24px 24px 16px 24px',
  },

  dialogActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '16px 24px 24px 24px',
  },

  button: {
    marginLeft: 8,
    marginTop: 12,
    width: 150,
  },

  buttonLoading: {
    width: 75,
  },
}));

function PhoneMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}

function CPFMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}

export default function UserManagementDialog() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { selectedUser, loading } = useSelector(state => state.admin);

  const [referenceUser, setReferenceUser] = useState(null);
  const [sendEmailOption, setSendEmailOption] = useState(false);

  const handleClose = () => {
    setReferenceUser(null);
    dispatch(clearSelectedUser());
  };

  const handleChange = (field, value) => {
    dispatch(updateSelectedUserInfo(field, value));
  };

  useEffect(() => {
    if (!referenceUser && selectedUser) setReferenceUser(selectedUser);
  }, [selectedUser, referenceUser]);

  useEffect(() => {
    if (!referenceUser?.is_active && selectedUser?.is_active) setSendEmailOption(true);
    else setSendEmailOption(false);
  }, [selectedUser, referenceUser]);

  return (
    <Dialog open={!!selectedUser} onClose={handleClose} maxWidth="sm">
      <Box className={classes.dialogTitle} display="flex" justifyContent="space-between" width="100%">
        <Typography variant="h4">
          <b>Cadastro de Usuário</b>
        </Typography>
        <IconButton size="small" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              name="username"
              autoComplete="off"
              value={selectedUser?.username || ''}
              onChange={e => handleChange(e.target.name, e.target.value)}
              variant="outlined"
              label="Nome"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              name="email"
              autoComplete="off"
              value={selectedUser?.email || ''}
              onChange={e => handleChange(e.target.name, e.target.value)}
              variant="outlined"
              placeholder="exemplo@gmail.com"
              label="Email"
              type="email"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              autoComplete="off"
              name="cpf"
              value={selectedUser?.cpf || ''}
              onChange={e => handleChange(e.target.name, e.target.value)}
              variant="outlined"
              placeholder="CPF"
              label="CPF"
              fullWidth
              InputProps={{
                inputComponent: CPFMask,
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              autoComplete="off"
              name="rg"
              value={selectedUser?.rg || ''}
              onChange={e => handleChange(e.target.name, e.target.value)}
              variant="outlined"
              placeholder="RG"
              label="RG"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              autoComplete="off"
              name="phone_number"
              value={selectedUser?.phone_number || ''}
              onChange={e => handleChange(e.target.name, e.target.value)}
              variant="outlined"
              placeholder="Telefone"
              label="Telefone"
              fullWidth
              InputProps={{
                inputComponent: PhoneMask,
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              autoComplete="off"
              name="address"
              value={selectedUser?.address || ''}
              onChange={e => handleChange(e.target.name, e.target.value)}
              variant="outlined"
              placeholder="Endereço"
              label="Endereço"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              autoComplete="off"
              name="profession"
              value={selectedUser?.profession || ''}
              onChange={e => handleChange(e.target.name, e.target.value)}
              variant="outlined"
              placeholder="Profissão"
              label="Profissão"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              autoComplete="off"
              name="license_number"
              value={selectedUser?.license_number || ''}
              onChange={e => handleChange(e.target.name, e.target.value)}
              variant="outlined"
              placeholder="Registro de Profissão (se aplicável)"
              label="Registro de Profissão"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={sendEmailOption ? 3 : 6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="is_active_label">Usuário ativo?</InputLabel>
              <Select
                name="is_active"
                value={selectedUser?.is_active || 0}
                onChange={e => handleChange(e.target.name, e.target.value)}
                label="Usuário ativo?"
              >
                <MenuItem value={0}>Não</MenuItem>
                <MenuItem value={1}>Sim</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {sendEmailOption && (
            <Grid item xs={12} md={3}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="send_email_label">Enviar email de ativação?</InputLabel>
                <Select
                  name="send_email"
                  value={selectedUser?.send_email || 0}
                  onChange={e => handleChange(e.target.name, e.target.value)}
                  label="Enviar email de ativação?"
                >
                  <MenuItem value={0}>Não</MenuItem>
                  <MenuItem value={1}>Sim</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="is_admin_label">Usuário administrador?</InputLabel>
              <Select
                name="is_admin"
                value={selectedUser?.is_admin || 0}
                onChange={e => handleChange(e.target.name, e.target.value)}
                label="Usuário administrador?"
              >
                <MenuItem value={0}>Não</MenuItem>
                <MenuItem value={1}>Sim</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="contained"
          onClick={() => dispatch(userUpdateByAdminRequest(selectedUser))}
          disabled={_.isEqual(selectedUser, referenceUser)}
          className={clsx(classes.button, {
            [classes.buttonLoading]: loading,
          })}
        >
          {loading ? <CircularProgress color="inherit" size={30} /> : 'Salvar Alterações'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
