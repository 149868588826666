import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';

import { Box, Grid, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { listRoomsRequest, newRoomSelection, setSelectedRoom } from '~/store/modules/room/actions';

import { formatCurrency } from '~/services/utils';
import RoomManagementDialog from './Dialog';

const useStyles = makeStyles(theme => ({
  basic: {
    fontSize: 12,
    padding: 5,
    height: 30,
    border: '1px solid #DDD',
    textAlign: 'center',
  },
  container: {
    height: 'calc(100vh - 215px)',
  },
  tableRow: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default function RoomManagement() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { rooms, loading } = useSelector(state => state.room);

  const handleNewRoomSelection = () => {
    dispatch(newRoomSelection());
  };

  const handleSelect = room => {
    dispatch(setSelectedRoom(room));
  };

  useEffect(() => {
    dispatch(listRoomsRequest());
  }, [dispatch]);

  return (
    <>
      <RoomManagementDialog />

      <Box m={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h5" style={{ fontWeight: 'bold', lineHeight: '48px' }} color="secondary">
                Gerência de Sala
              </Typography>

              <Button onClick={handleNewRoomSelection} variant="contained" color="primary" width={200}>
                Registrar Sala
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12}>
            {!loading && (
              <TableContainer className={classes.container}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.basic} style={{ textAlign: 'left', fontWeight: 'bold' }}>
                        Nome
                      </TableCell>
                      <TableCell className={classes.basic} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        Preço Hora
                      </TableCell>
                      <TableCell className={classes.basic} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        Preço Hora/Turno
                      </TableCell>
                      <TableCell className={classes.basic} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        Status
                      </TableCell>
                      <TableCell className={classes.basic} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        Data de Criação
                      </TableCell>
                      <TableCell className={classes.basic} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        Última Atualização
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rooms.map(room => {
                      const { id, name, hour_price, shift_hourly_price, is_active, created_at, updated_at } = room;

                      return (
                        <TableRow hover key={id} className={classes.tableRow} onClick={() => handleSelect(room)}>
                          <TableCell className={classes.basic} style={{ textAlign: 'left' }}>
                            {name}
                          </TableCell>
                          <TableCell className={classes.basic} style={{ textAlign: 'center' }}>
                            {formatCurrency(hour_price)}
                          </TableCell>
                          <TableCell className={classes.basic} style={{ textAlign: 'center' }}>
                            {formatCurrency(shift_hourly_price)}
                          </TableCell>
                          <TableCell className={classes.basic} style={{ textAlign: 'center' }}>
                            {is_active ? 'Ativa' : 'Inativa'}
                          </TableCell>
                          <TableCell className={classes.basic} style={{ textAlign: 'center' }}>
                            {created_at}
                          </TableCell>
                          <TableCell className={classes.basic} style={{ textAlign: 'center' }}>
                            {updated_at}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
