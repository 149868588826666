export function createShiftReservationRequest(selectedShift) {
  return {
    type: '@shift_reservation/CREATE_SHIFT_RESERVATION_REQUEST',
    payload: {
      date: selectedShift.date,
      period: selectedShift.period,
      room_id: selectedShift.room.id,
      notes: selectedShift.notes,
    },
  };
}

export function createShiftReservationSuccess(reservations) {
  return {
    type: '@shift_reservation/CREATE_SHIFT_RESERVATION_SUCCESS',
    payload: {
      reservations,
    },
  };
}

export function createShiftReservationFailure() {
  return {
    type: '@shift_reservation/CREATE_SHIFT_RESERVATION_FAILURE',
  };
}

export function updateShiftReservationRequest(selectedShift) {
  return {
    type: '@shift_reservation/UPDATE_SHIFT_RESERVATION_REQUEST',
    payload: {
      id: selectedShift.id,
      notes: selectedShift.notes,
    },
  };
}

export function updateShiftReservationSuccess(reservations) {
  return {
    type: '@shift_reservation/UPDATE_SHIFT_RESERVATION_SUCCESS',
    payload: {
      reservations,
    },
  };
}

export function updateShiftReservationFailure() {
  return {
    type: '@shift_reservation/UPDATE_SHIFT_RESERVATION_FAILURE',
  };
}

export function deleteShiftReservationRequest(shiftReservationID) {
  return {
    type: '@shift_reservation/DELETE_SHIFT_RESERVATION_REQUEST',
    payload: {
      shiftReservationID,
    },
  };
}

export function deleteShiftReservationSuccess(deletedShift) {
  return {
    type: '@shift_reservation/DELETE_SHIFT_RESERVATION_SUCCESS',
    payload: {
      deletedShift,
    },
  };
}

export function deleteShiftReservationFailure() {
  return {
    type: '@shift_reservation/DELETE_SHIFT_RESERVATION_FAILURE',
  };
}
