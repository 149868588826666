import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';

import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { userListRequest, setSelectedUser } from '~/store/modules/admin/actions';

import UserManagementDialog from './Dialog';

import { rgba } from 'polished';
import { formatCPF, formatPhone } from '~/services/utils';

const useStyles = makeStyles(theme => ({
  basic: {
    fontSize: 12,
    padding: 5,
    height: 38,
    border: '1px solid #DDD',
    textAlign: 'center',
  },
  container: {
    height: 'calc(100vh - 215px)',
  },
  tableRow: {
    background: '#FFF',
    '&:hover': {
      background: `${rgba(10, 35, 130, 0.15)} !important`,
      cursor: 'pointer',
    },
  },
}));

export default function UserManagement() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { users, loading } = useSelector(state => state.admin);

  const handleSelect = user => {
    dispatch(setSelectedUser(user));
  };

  useEffect(() => {
    dispatch(userListRequest());
  }, [dispatch]);

  return (
    <>
      <UserManagementDialog />

      <Box m={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h5" style={{ fontWeight: 'bold', lineHeight: '48px' }} color="secondary">
                Gerência de Usuário
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            {!loading && (
              <TableContainer className={classes.container}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.basic} style={{ textAlign: 'left', fontWeight: 'bold' }}>
                        Nome
                      </TableCell>
                      <TableCell className={classes.basic} style={{ textAlign: 'left', fontWeight: 'bold' }}>
                        Email
                      </TableCell>
                      <TableCell className={classes.basic} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        CPF
                      </TableCell>
                      <TableCell className={classes.basic} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        Telefone
                      </TableCell>
                      <TableCell className={classes.basic} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        Código Porta
                      </TableCell>
                      <TableCell className={classes.basic} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        Contrato
                      </TableCell>
                      <TableCell className={classes.basic} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map(user => {
                      const {
                        id,
                        username,
                        email,
                        is_active: isActive,
                        cpf,
                        phone_number: phoneNumber,
                        access_password: accessPassword,
                        has_contract: hasContract,
                      } = user;

                      return (
                        <TableRow hover key={id} className={classes.tableRow} onClick={() => handleSelect(user)}>
                          <TableCell className={classes.basic} style={{ textAlign: 'left' }}>
                            {username}
                          </TableCell>
                          <TableCell className={classes.basic} style={{ textAlign: 'left' }}>
                            {email}
                          </TableCell>
                          <TableCell className={classes.basic} style={{ textAlign: 'center' }}>
                            {formatCPF(cpf)}
                          </TableCell>
                          <TableCell className={classes.basic} style={{ textAlign: 'center' }}>
                            {formatPhone(phoneNumber)}
                          </TableCell>
                          <TableCell className={classes.basic} style={{ textAlign: 'center' }}>
                            {accessPassword}
                          </TableCell>
                          <TableCell className={classes.basic} style={{ textAlign: 'center' }}>
                            {hasContract ? 'Sim' : 'Não'}
                          </TableCell>
                          <TableCell className={classes.basic} style={{ textAlign: 'center' }}>
                            {isActive ? 'Ativo' : 'Inativo'}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
