export function listContractsRequest() {
  return {
    type: '@contract/LIST_CONTRACTS_REQUEST',
  };
}

export function listContractsSuccess(contracts) {
  return {
    type: '@contract/LIST_CONTRACTS_SUCCESS',
    payload: {
      contracts,
    },
  };
}

export function listContractsFailure() {
  return {
    type: '@contract/LIST_CONTRACTS_FAILURE',
  };
}

export function clearSelectedContract() {
  return {
    type: '@contract/CLEAR_SELECTED_CONTRACT',
  };
}

export function setSelectedContract(selectedContract) {
  return {
    type: '@contract/SET_SELECTED_CONTRACT',
    payload: {
      selectedContract,
    },
  };
}

export function updateSelectedContractInfo(field, value) {
  return {
    type: '@contract/UPDATE_SELECTED_CONTRACT_INFO',
    payload: {
      field,
      value,
    },
  };
}

export function fetchLockedTimesRequest(roomID, startDate, endDate) {
  return {
    type: '@contract/FETCH_LOCKED_TIMES_REQUEST',
    payload: {
      room_id: roomID,
      start_date: startDate,
      end_date: endDate,
    },
  };
}

export function fetchLockedTimesSuccess(lockedTimes) {
  return {
    type: '@contract/FETCH_LOCKED_TIMES_SUCCESS',
    payload: {
      lockedTimes,
    },
  };
}

export function fetchLockedTimesFailure() {
  return {
    type: '@contract/FETCH_LOCKED_TIMES_FAILURE',
  };
}

export function clearLockedTimes() {
  return {
    type: '@contract/CLEAR_LOCKED_TIMES',
  };
}

export function checkContractRequest(contract) {
  return {
    type: '@contract/CHECK_CONTRACT_REQUEST',
    payload: {
      contract,
    },
  };
}

export function checkContractSuccess(reservations) {
  return {
    type: '@contract/CHECK_CONTRACT_SUCCESS',
    payload: {
      reservations,
    },
  };
}

export function checkContractFailure() {
  return {
    type: '@contract/CHECK_CONTRACT_FAILURE',
  };
}

export function clearCheckedReservations() {
  return {
    type: '@contract/CLEAR_CHECKED_RESERVATIONS',
  };
}

export function createContractRequest(contract, reservations) {
  return {
    type: '@contract/CREATE_CONTRACT_REQUEST',
    payload: {
      contract,
      reservations,
    },
  };
}

export function createContractSuccess(contract) {
  return {
    type: '@contract/CREATE_CONTRACT_SUCCESS',
    payload: {
      contract,
    },
  };
}

export function createContractFailure() {
  return {
    type: '@contract/CREATE_CONTRACT_FAILURE',
  };
}

export function deleteContractRequest(contractID, cancelationDate) {
  return {
    type: '@contract/DELETE_CONTRACT_REQUEST',
    payload: {
      contractID,
      cancelationDate,
    },
  };
}

export function deleteContractSuccess(deletedContract) {
  return {
    type: '@contract/DELETE_CONTRACT_SUCCESS',
    payload: {
      deletedContract,
    },
  };
}

export function deleteContractFailure() {
  return {
    type: '@contract/DELETE_CONTRACT_FAILURE',
  };
}
