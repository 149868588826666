import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '~/pages/SignIn';
import SignUp from '~/pages/SignUp';
import AfterSignUp from '~/pages/AfterSignUp';
import ForgotPassword from '~/pages/ForgotPassword';
import PasswordReset from '~/pages/PasswordReset';

import Overview from '~/pages/Overview';
import Calendar from '~/pages/Calendar';
import Statement from '~/pages/Statement';

import Profile from '~/pages/Profile';

import AdminStatement from '~/pages/Admin/Statement';
import Contract from '~/pages/Admin/Contract';
import UserManagement from '~/pages/Admin/User';
import RoomManagement from '~/pages/Admin/Room';
import ReservationManagement from '~/pages/Admin/Reservation';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/register" component={SignUp} />
      <Route path="/register_success" component={AfterSignUp} />
      <Route path="/forgot_password" component={ForgotPassword} />
      <Route path="/password_reset/:token" component={PasswordReset} />

      <Route path="/overview" component={Overview} isPrivate />
      <Route path="/hour_calendar" component={Calendar} isPrivate />
      <Route path="/shift_calendar" component={Calendar} isPrivate />
      <Route path="/statement" component={Statement} isPrivate />
      <Route path="/profile" component={Profile} isPrivate />

      <Route path="/management/statement" component={AdminStatement} isPrivate isAdmin />
      <Route path="/management/contract" component={Contract} isPrivate isAdmin />
      <Route path="/management/user" component={UserManagement} isPrivate isAdmin />
      <Route path="/management/room" component={RoomManagement} isPrivate isAdmin />
      <Route path="/management/reservation" component={ReservationManagement} isPrivate isAdmin />
    </Switch>
  );
}
