const weekdays = {
  0: 'Dom',
  1: 'Seg',
  2: 'Ter',
  3: 'Qua',
  4: 'Qui',
  5: 'Sex',
  6: 'Sáb',
};

const daysLabelMap = {
  sunday: 'Domingo',
  monday: 'Segunda',
  tuesday: 'Terça',
  wednesday: 'Quarta',
  thursday: 'Quinta',
  friday: 'Sexta',
  saturday: 'Sábado',
};

const shortMonths = {
  1: 'Jan',
  2: 'Fev',
  3: 'Mar',
  4: 'Abr',
  5: 'Maio',
  6: 'Jun',
  7: 'Jul',
  8: 'Ago',
  9: 'Set',
  10: 'Out',
  11: 'Nov',
  12: 'Dez',
};

const longMonths = {
  1: 'Janeiro',
  2: 'Fevereiro',
  3: 'Março',
  4: 'Abril',
  5: 'Maio',
  6: 'Junho',
  7: 'Julho',
  8: 'Agosto',
  9: 'Setembro',
  10: 'Outubro',
  11: 'Novembro',
  12: 'Dezembro',
};

const daysOfWeek = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'];

const daysOfWeekNumbers = {
  Segunda: 1,
  Terça: 2,
  Quarta: 3,
  Quinta: 4,
  Sexta: 5,
  Sábado: 6,
  Domingo: 0,
};

const hoursOfDay = [
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
];

const statementStatus = {
  PAYMENT_PENDING: 'Pendente',
  PAYED: 'Pago',
};

const shiftLabel = {
  MORNING: 'Manhã',
  EVENING: 'Tarde',
  NIGHT: 'Noite',
};

const shiftHours = {
  MORNING: ['08:00', '09:00', '10:00', '11:00'],
  EVENING: ['14:00', '15:00', '16:00', '17:00'],
  NIGHT: ['18:00', '19:00', '20:00', '21:00'],
};

const hoursToShift = {
  '08:00': 'MORNING',
  '09:00': 'MORNING',
  '10:00': 'MORNING',
  '11:00': 'MORNING',

  '14:00': 'EVENING',
  '15:00': 'EVENING',
  '16:00': 'EVENING',
  '17:00': 'EVENING',

  '18:00': 'NIGHT',
  '19:00': 'NIGHT',
  '20:00': 'NIGHT',
  '21:00': 'NIGHT',
};

export const formatPhone = phone => {
  phone = phone.replace(/\D/g, '');

  if (phone.length !== 10 && phone.length !== 11) return phone;

  if (phone.length === 10) phone = phone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  else phone = phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');

  return phone;
};

export const formatCPF = cpf => {
  cpf = cpf.replace(/\D/g, '');

  if (cpf.length !== 11) return cpf;

  cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

  return cpf;
};

export const getRoomName = (map, room) => (map ? map[room] || 'Sala Indefinida' : 'Carregando');

export const getDayLabel = day => daysLabelMap[day] || 'Dia Indefinido';

export const getHoursOfDay = () => {
  return hoursOfDay;
};

export const getDaysOfWeekNumbers = () => {
  return daysOfWeekNumbers;
};

export const getDaysOfWeek = () => {
  return daysOfWeek;
};

export const getShiftList = () => {
  return Object.keys(shiftLabel);
};

export const getShiftLabel = shift => {
  return shiftLabel[shift];
};

export const getShiftHours = shift => {
  return shiftHours[shift];
};

export const getShiftFromHour = hour => {
  return hoursToShift[hour];
};

export const getTimeLabel = (time, type) => {
  if (type === 'HOUR') return time;
  return shiftLabel[time];
};

export const getReservationTimeLabel = reservation => {
  if (reservation.shift_id) return shiftLabel[reservation.time];
  return reservation.time;
};

const getMonth = (m, variant = 'short') => {
  if (variant === 'long') return longMonths[m];
  else return shortMonths[m];
};

export const cloneObject = obj => {
  return JSON.parse(JSON.stringify(obj));
};

export const formatRawDate = date => {
  const dd = date.getDate();
  const mm = date.getMonth() + 1;
  const yyyy = date.getFullYear();

  const formattedDay = `${dd > 9 ? '' : '0'}${dd}`;
  const formattedMonth = `${mm > 9 ? '' : '0'}${mm}`;

  return `${yyyy}-${formattedMonth}-${formattedDay}`;
};

export const formatPrettyDate = date => {
  const weekday = date.getDay();
  const dd = date.getDate();
  const mm = date.getMonth() + 1;
  const formattedDay = `${dd > 9 ? '' : '0'}${dd}`;

  return `${weekdays[weekday]}, ${formattedDay} ${getMonth(mm)}`;
};

export const stringToPrettyDate = stringDate => {
  var [year, month, date] = stringDate.split('-');
  month = parseInt(month, 10) - 1;
  return formatPrettyDate(new Date(year, month, date));
};

export const formatPrettyLongDate = date => {
  const weekday = date.getDay();
  const dd = date.getDate();
  const mm = date.getMonth() + 1;
  const yyyy = date.getFullYear();
  const formattedDay = `${dd > 9 ? '' : '0'}${dd}`;

  return `${weekdays[weekday]}, ${formattedDay} ${getMonth(mm)} ${yyyy}`;
};

export const stringToLongPrettyDate = stringDate => {
  var [year, month, date] = stringDate.split('-');
  month = parseInt(month, 10) - 1;
  return formatPrettyLongDate(new Date(year, month, date));
};

export const currentMonthAndYear = () => {
  const today = new Date();
  const mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();

  return `${getMonth(mm, 'long')}/${yyyy}`;
};

export const getCurrentTime = () => {
  const today = new Date();
  const hh = today.getHours();
  const min = today.getMinutes();
  return `${hh > 9 ? '' : '0'}${hh}:${min > 9 ? '' : '0'}${min}`;
};

export const isPast = (date, time) => {
  const now = new Date();
  const eventDateTime = new Date(`${date} ${time}`);
  return eventDateTime < now;
};

const isRecentReservation = createdAt => {
  const eventDate = new Date(createdAt);

  const currentDate = new Date();

  const timeDifference = (currentDate - eventDate) / 1000 / 60;

  return timeDifference < 30;
};

export const isCancelable = reservation => {
  const { date, time, period, created_at: createdAt, contract_id: contractID } = reservation;

  if (contractID) return false;

  if (isRecentReservation(createdAt)) return true;

  const limitDate = new Date();
  limitDate.setDate(limitDate.getDate() + 1);

  const eventDateTime = new Date(`${date} ${period ? shiftHours[period][0] : time}`);

  return eventDateTime > limitDate;
};

export const getReservationPrice = (room, price, shiftID) => {
  let value = null;

  if (price) {
    value = price;
  } else if (!shiftID) {
    value = room.hour_price;
  } else if (shiftID) {
    value = room.shift_hourly_price;
  } else {
    value = 0.0;
  }

  return value;
};

export const formatCurrency = value => {
  value = +value;
  return `R$ ${value.toFixed(2)}`;
};

export const getPrettyStatementStatus = value => {
  return statementStatus[value];
};
