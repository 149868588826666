import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import clsx from 'clsx';
import { rgba } from 'polished';

import { isMobile } from 'react-device-detect';
import _ from 'lodash';
import { isPast, getHoursOfDay } from '~/services/utils';

import HourlyDialog from './HourlyDialog';

const useStyles = makeStyles(() => ({
  basic: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 100,
    fontSize: 12,
    borderRadius: 10,
    border: '3px solid #F9F9F9',
    textAlign: 'center',
  },

  available: {
    cursor: 'pointer',
    background: rgba(0, 155, 159, 0.2),
    '&:hover': {
      background: rgba(0, 155, 159, 0.3),
    },
  },

  reserved: {
    cursor: 'pointer',
    background: rgba(10, 35, 130, 0.15),
    '&:hover': {
      background: rgba(10, 35, 130, 0.25),
    },
  },

  reservedShift: {
    background: rgba(10, 35, 130, 0.1),
  },

  locked: {
    background: rgba(0, 0, 0, 0.03),
    color: rgba(0, 0, 0, 0.26),
  },

  hoveredAvailable: {
    fontWeight: 'bolder',
    background: rgba(0, 155, 159, 0.3),
  },

  hoveredReserved: {
    fontWeight: 'bolder',
    background: rgba(10, 35, 130, 0.25),
  },
}));

export default function Overview({ calendarCurrentSlice, userReservedMap, selectedRoom }) {
  const classes = useStyles();

  const { lockedReservationsMap } = useSelector(state => state.reservation);

  const hoursOfDay = getHoursOfDay();

  const [selectedReservation, setSelectedReservation] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);

  const [hoveredTime, setHoveredTime] = useState(null);
  const [hoveredDate, setHoveredDate] = useState(null);
  const [hoveredStatus, setHoveredStatus] = useState(null);

  const handleMouseEnter = (time, date, status, shiftID) => {
    if (!shiftID) {
      setHoveredTime(time);
      setHoveredDate(date);
      setHoveredStatus(status);
    }
  };

  const handleMouseLeave = () => {
    setHoveredTime(null);
    setHoveredDate(null);
    setHoveredStatus(null);
  };

  return (
    <>
      <HourlyDialog
        selectedReservation={selectedReservation}
        setSelectedReservation={setSelectedReservation}
        selectedAction={selectedAction}
        setSelectedAction={setSelectedAction}
      />

      <Table size="small" style={{ height: 'calc(100vh - 120px)' }}>
        <Hidden xsDown>
          <TableHead>
            <TableRow>
              <TableCell className={classes.basic} style={{ width: 40, textAlign: 'left' }} />
              {calendarCurrentSlice.map(([rawDate, formattedDate]) => (
                <TableCell
                  className={clsx(classes.basic, {
                    [classes.hoveredAvailable]: rawDate === hoveredDate && hoveredStatus === 'AVAILABLE',
                    [classes.hoveredReserved]: rawDate === hoveredDate && hoveredStatus === 'RESERVED',
                  })}
                  style={{ fontWeight: 'bold' }}
                  key={rawDate}
                  align="center"
                >
                  {formattedDate}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        </Hidden>

        <TableBody onMouseLeave={() => handleMouseLeave()}>
          {hoursOfDay.map(time => (
            <TableRow key={time}>
              <Hidden xsDown>
                <TableCell
                  className={clsx(classes.basic, {
                    [classes.hoveredAvailable]: time === hoveredTime && hoveredStatus === 'AVAILABLE',
                    [classes.hoveredReserved]: time === hoveredTime && hoveredStatus === 'RESERVED',
                  })}
                  style={{ fontWeight: 'bold', textAlign: 'center' }}
                >
                  {time}
                </TableCell>
              </Hidden>
              {calendarCurrentSlice.map(([rawDate]) => {
                const key = `${rawDate} ${time}`;
                const roomKey = `${key} ${selectedRoom.id}`;

                let status = 'AVAILABLE';
                let shiftID = false;
                let existingReservation;

                if (key in userReservedMap) {
                  status = 'RESERVED';
                  existingReservation = _.cloneDeep(userReservedMap[key]);
                  // existingReservation.room = selectedRoom;
                  shiftID = existingReservation.shift_id;
                } else if (roomKey in lockedReservationsMap || isPast(rawDate, time)) status = 'LOCKED';

                return (
                  <TableCell
                    onMouseEnter={() => handleMouseEnter(time, rawDate, status, shiftID)}
                    className={clsx(classes.basic, {
                      [classes.available]: status === 'AVAILABLE',
                      [classes.reserved]: status === 'RESERVED' && !shiftID,
                      [classes.reserved_shift]: status === 'RESERVED' && shiftID,
                      [classes.locked]: status === 'LOCKED',
                    })}
                    key={key}
                    onClick={() => {
                      if (status === 'AVAILABLE') {
                        setSelectedReservation({
                          date: rawDate,
                          time,
                          room: selectedRoom,
                          note: null,
                        });
                        setSelectedAction('create');
                      } else if (status === 'RESERVED' && !shiftID) {
                        setSelectedReservation(existingReservation);
                        setSelectedAction('update');
                      }
                    }}
                  >
                    {isMobile && time}
                    {status === 'AVAILABLE' && !isMobile && 'Disponível'}
                    {status === 'RESERVED' && !isMobile && `${existingReservation.note || existingReservation.room.name}`}
                    {status === 'LOCKED' && !isMobile && 'Indisponível'}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
