import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ForwardIcon from '@material-ui/icons/ArrowForwardIos';
import BackIcon from '@material-ui/icons/ArrowBackIos';

import IconButton from '@material-ui/core/IconButton';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { isMobile } from 'react-device-detect';

import { listUserUpcomingRequest, listLockedRequest } from '~/store/modules/reservation/actions';

import HourlyReservation from './HourlyReservation';
import ShiftReservation from './ShiftReservation';

const useStyles = makeStyles(() => ({
  root: {
    padding: 12,
  },
}));

export default function Overview() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { dates, userUpcomingReservations } = useSelector(state => state.reservation);
  const { rooms } = useSelector(state => state.room);

  const [pageSize] = useState(isMobile ? 1 : 7);
  const [selectedRoom, setSelectedRoom] = useState();
  const [calendarPage, setCalendarPage] = useState(0);
  const [calendarStartDate, setCalendarStateDate] = useState([]);
  const [calendarEndDate, setCalendarEndDate] = useState([]);
  const [calendarCurrentSlice, setCalendarCurrentSlice] = useState([]);

  const [userReservedMap, setUserReservedMap] = useState({});

  useEffect(() => {
    if (dates.length > 0) {
      dispatch(listLockedRequest());
      if (userUpcomingReservations.length === 0) {
        dispatch(listUserUpcomingRequest());
      }
    }
  }, [dates, userUpcomingReservations.length, dispatch]);

  useEffect(() => {
    const updatedUserReservedMap = {};
    userUpcomingReservations.forEach(reservation => {
      const key = `${reservation.date} ${reservation.time}`;
      updatedUserReservedMap[key] = reservation;
    });
    setUserReservedMap(updatedUserReservedMap);
  }, [userUpcomingReservations]);

  useEffect(() => {
    if (dates && dates.length > 0) {
      const startDateIndex = calendarPage * pageSize;
      setCalendarCurrentSlice(dates.slice(startDateIndex, startDateIndex + pageSize));

      const [, sDate] = dates[startDateIndex] || '';
      const [, eDate] = dates[startDateIndex + (pageSize - 1)] || '';

      setCalendarStateDate(sDate.split(',')[1]);
      setCalendarEndDate(eDate.split(',')[1]);
    }
  }, [dates, calendarPage, pageSize]);

  return (
    <>
      <Box align="center" className={classes.root}>
        <Hidden smUp>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="h5" style={{ fontWeight: 'bold', lineHeight: '48px' }} color="secondary">
              Agendar Reservas
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center" width="100%" height={48}>
            <IconButton
              onClick={() => setCalendarPage(calendarPage - 1)}
              disabled={calendarPage <= 0}
              color="secondary"
              style={{ height: 24, width: 24 }}
            >
              <BackIcon fontSize="small" style={{ marginRight: -7 }} />
            </IconButton>

            <Typography
              variant="h6"
              color="secondary"
              style={{ minWidth: 80, lineHeight: '48px', marginLeft: 40, marginRight: 40 }}
              align="center"
            >
              {calendarStartDate}
            </Typography>
            <IconButton
              onClick={() => setCalendarPage(calendarPage + 1)}
              disabled={calendarPage >= 3}
              color="secondary"
              style={{ height: 24, width: 24 }}
            >
              <ForwardIcon fontSize="small" />
            </IconButton>
          </Box>
        </Hidden>

        <Hidden xsDown>
          <Box display="flex" justifyContent="space-between" width="100%" paddingLeft="60px">
            <Typography variant="h5" style={{ fontWeight: 'bold', lineHeight: '36px' }} color="secondary">
              Agendar Reservas
            </Typography>

            <Box display="flex" alignItems="center">
              <IconButton
                onClick={() => setCalendarPage(calendarPage - 1)}
                disabled={calendarPage <= 0}
                color="secondary"
                style={{ height: 24, width: 24 }}
              >
                <BackIcon fontSize="small" style={{ marginRight: -7 }} />
              </IconButton>
              <Typography variant="h6" color="secondary" style={{ minWidth: 140 }} align="center">
                {calendarStartDate} até {calendarEndDate}
              </Typography>
              <IconButton
                onClick={() => setCalendarPage(calendarPage + 1)}
                disabled={calendarPage >= 3}
                color="secondary"
                style={{ height: 24, width: 24 }}
              >
                <ForwardIcon fontSize="small" />
              </IconButton>
            </Box>
            <Box width={250}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel id="room">Selecione uma sala</InputLabel>
                <Select
                  name="room_id"
                  value={selectedRoom}
                  onChange={e => setSelectedRoom(e.target.value)}
                  label="Selecione uma Sala"
                  style={{ textAlign: 'left' }}
                >
                  {rooms.map(option => (
                    <MenuItem key={option.id} value={option} disabled={!option.is_active}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Hidden>
        {selectedRoom && (
          <>
            {window.location.pathname === '/shift_calendar' ? (
              <ShiftReservation calendarCurrentSlice={calendarCurrentSlice} userReservedMap={userReservedMap} selectedRoom={selectedRoom} />
            ) : (
              <HourlyReservation
                calendarCurrentSlice={calendarCurrentSlice}
                userReservedMap={userReservedMap}
                selectedRoom={selectedRoom}
              />
            )}
          </>
        )}
      </Box>
    </>
  );
}
