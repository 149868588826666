export function setDates(dates) {
  return {
    type: '@reservation/SET_DATES',
    payload: {
      dates,
    },
  };
}

export function clearSelectedReservation() {
  return {
    type: '@reservation/CLEAR_SELECTED_RESERVATION',
  };
}

export function setSelectedReservation(selectedReservation, action) {
  return {
    type: '@reservation/SET_SELECTED_RESERVATION',
    payload: {
      selectedReservation,
      action,
    },
  };
}

export function updateSelectedReservationInfo(field, value) {
  return {
    type: '@reservation/UPDATE_SELECTED_RESERVATION_INFO',
    payload: {
      field,
      value,
    },
  };
}

export function setSelectedReservations(selectedReservations) {
  return {
    type: '@reservation/SET_SELECTED_RESERVATIONS',
    payload: {
      selectedReservations,
    },
  };
}

export function setSelectedRooms(selectedRooms) {
  return {
    type: '@reservation/SET_SELECTED_ROOMS',
    payload: {
      selectedRooms,
    },
  };
}

export function createHourReservationRequest(newReservation) {
  return {
    type: '@reservation/CREATE_HOUR_RESERVATION_REQUEST',
    payload: {
      newReservation,
    },
  };
}

export function createHourReservationSuccess(reservation) {
  return {
    type: '@reservation/CREATE_HOUR_RESERVATION_SUCCESS',
    payload: {
      reservation,
    },
  };
}

export function createHourReservationFailure() {
  return {
    type: '@reservation/CREATE_HOUR_RESERVATION_FAILURE',
  };
}

export function updateHourReservationRequest(existingReservation) {
  return {
    type: '@reservation/UPDATE_HOUR_RESERVATION_REQUEST',
    payload: {
      existingReservation,
    },
  };
}

export function updateHourReservationSuccess(reservation) {
  return {
    type: '@reservation/UPDATE_HOUR_RESERVATION_SUCCESS',
    payload: {
      reservation,
    },
  };
}

export function updateHourReservationFailure() {
  return {
    type: '@reservation/UPDATE_HOUR_RESERVATION_FAILURE',
  };
}

export function mergeReservationRequest(reservation, action) {
  return {
    type: '@reservation/MERGE_RESERVATION_REQUEST',
    payload: {
      reservation,
      action,
    },
  };
}

export function mergeReservationSuccess(result) {
  return {
    type: '@reservation/MERGE_RESERVATION_SUCCESS',
    payload: {
      result,
    },
  };
}

export function mergeReservationFailure() {
  return {
    type: '@reservation/MERGE_RESERVATION_FAILURE',
  };
}

export function deleteHourReservationRequest(reservationID) {
  return {
    type: '@reservation/DELETE_HOUR_RESERVATION_REQUEST',
    payload: {
      reservationID,
    },
  };
}

export function deleteHourReservationSuccess(deletedReservation) {
  return {
    type: '@reservation/DELETE_HOUR_RESERVATION_SUCCESS',
    payload: {
      deletedReservation,
    },
  };
}

export function deleteHourReservationFailure() {
  return {
    type: '@reservation/DELETE_HOUR_RESERVATION_FAILURE',
  };
}

export function listUserUpcomingRequest() {
  return {
    type: '@reservation/LIST_USER_UPCOMING_REQUEST',
  };
}

export function listUserUpcomingSuccess(userUpcomingReservations) {
  return {
    type: '@reservation/LIST_USER_UPCOMING_SUCCESS',
    payload: {
      userUpcomingReservations,
    },
  };
}

export function listUserUpcomingFailure() {
  return {
    type: '@reservation/LIST_USER_UPCOMING_FAILURE',
  };
}

export function listLockedRequest() {
  return {
    type: '@reservation/LIST_LOCKED_REQUEST',
  };
}

export function listLockedSuccess(lockedReservationsMap) {
  return {
    type: '@reservation/LIST_LOCKED_SUCCESS',
    payload: {
      lockedReservationsMap,
    },
  };
}

export function listLockedFailure() {
  return {
    type: '@reservation/LIST_LOCKED_FAILURE',
  };
}

export function listUserReservationsByMonthRequest() {
  return {
    type: '@reservation/LIST_USER_RESERVATION_BY_MONTH_REQUEST',
  };
}

export function listUserReservationsByMonthSuccess(reservationsByMonth) {
  return {
    type: '@reservation/LIST_USER_RESERVATION_BY_MONTH_SUCCESS',
    payload: {
      reservationsByMonth,
    },
  };
}

export function listUserReservationsByMonthFailure() {
  return {
    type: '@reservation/LIST_USER_RESERVATION_BY_MONTH_FAILURE',
  };
}
